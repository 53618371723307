// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";

import {
  Box,
} from "@mui/material";
import { MemberApi } from "../api/MemberApi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import ResetPasswordForm from "../components/ResetPasswordForm";

export default function ResetPasswordSection() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();



  const onResetPassword = async (formData) => {
    console.debug("handleSubmit: ", formData);
    setLoading(true);

    try {
      const result = await MemberApi.resetPassword(formData.account);
      console.debug("result: ", result);

      await ReactSwal.fire({
        // title: '請輸入電郵或聯絡電話',
        html: '密碼已經重設，請檢查你的郵件',
        //html: response.data.message || "Unknown error",
      });
      // alert('popup done');

      navigate("/login");
    } catch (error) {
      console.error("error: ", error);
    }


    setLoading(false);
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" >
      <ResetPasswordForm onSubmit={onResetPassword} loading={loading} />
    </Box>
  );
}
