import React from "react";
import { Card, Box, Button, Typography, Grid, Stack } from "@mui/material";
import { useTranslation, Translation } from "react-i18next";
import QRCode from "react-qr-code";

const required_count = process.env.REACT_APP_REQUIRED_REDEEMED;

function formatValue(t, value, name = 'unit') {
    var str = value.toString() + t("redemption." + name);

    return "<strong>" + str + "</strong>";
}

function formatTime(dateTimeStr, format = 'hhmm') {
    if(format === 'hhmm') { 
        return dateTimeStr.substring(11, 16);
    }

    return dateTimeStr;
}

function getNotReadyLines(t, eventRedemptionInfo) {
    // const { isRedeemable, isRedeemed, isExpired } = eventRedemptionInfo;
    const { total_count, complete_count } = eventRedemptionInfo;
    
    let lines = [];
    let line, richText;

    // Line 1
    line = t("redemption.not_ready_line1")
    richText = line.replace("{total}", formatValue(t, total_count));
    richText = richText.replace("{complete}", formatValue(t, complete_count));
    richText = richText.replace("{required}", formatValue(t, required_count));
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);


    // Line 2
    richText = t("redemption.not_ready_line2")
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);

    // Line 3
    richText = t("redemption.not_ready_line3")
    richText = richText.replace("{total}", "<strong>" + total_count + "</strong>");
    richText = richText.replace("{required}", "<strong>" + required_count + "</strong>");
    richText = richText.replace("{complete}", "<strong>" + complete_count + "</strong>");
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);

    return lines;
}


function getReadyLines(t, eventRedemptionInfo) {
    // const { isRedeemable, isRedeemed, isExpired } = eventRedemptionInfo;
    const { total_count } = eventRedemptionInfo;

    const reward_chance = 1;

    let lines = [];
    let line, richText;

    // Line 1
    line = t("redemption.ready_line")
    richText = line.replace("{required}", formatValue(t, required_count, "unit"));
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);

    

    line = t("redemption.ready_line2")
    richText = line.replace("{count}", formatValue(t, reward_chance, "time"));
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);

    return lines;
}

function getRedeemedLines(t, eventRedemptionInfo) {
    // const { isRedeemable, isRedeemed, isExpired } = eventRedemptionInfo;
    const { updated_at, } = eventRedemptionInfo;

    let lines = [];

    // Line 1
    let richText = t("redemption.redeemed_line")
    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);


    richText = t("redemption.redeemed_line2")
    let timeStr = formatTime(updated_at, 'hhmm');
    richText = richText.replace("{redeem_time}", timeStr);

    lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }} />);
    // richText = line.replace("{total}", formatUnit(t, total_count));
    // lines.push(<Typography dangerouslySetInnerHTML={{ __html: richText }}/>);

    return lines;
}

function getQRCodeColor(isReceived, ageGroup) {
    if (isReceived) {
        return "#aaa"
    }
    return ageGroup === 'child' ? '#aaf' : "#afa";
}

function getRedemptionQRCode(t, redemptionInfo, ageGroup) {
    const { qrcode, status } = redemptionInfo;

    if (status === "not_ready") {
        return (<></>);
    }

    const isReceived = status === "redeemed";

    const bgColor = getQRCodeColor(isReceived, ageGroup)


    const msg = isReceived ? t("redemption.redeemed_qrcode_msg") : t("redemption.ready_qrcode_msg");

    return (<Card variant="outlined" sx={{ p: 1, mb: 1, background: "#fff" }}>
        <Grid
            container
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
        >
            <Grid item>
                <Typography variant="h5" fontWeight={400}>
                    {qrcode}
                </Typography>
            </Grid>
            <Grid item>
                <Box
                    component={QRCode}
                    size={100}
                    value={qrcode}
                    bgColor={bgColor}
                />
            </Grid>
            <Typography variant="h5" fontWeight={400}>
                {msg}
            </Typography>
        </Grid>
    </Card>);
}

export default function EventRedemptionInfo({ redemptionInfo, ageGroup }) {
    const { t } = useTranslation();

    const style = {
        // card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        background: "#fb0",
        textAlign: "center",
        padding: 1,
        mb: 3,
        // },
        // cardContent: {
        //     flexGrow: 1,
        // },
        // cardAction: {
        //     justifyContent: "flex-end",
        // },
    };


    // var lines = getNotReadyLines(t, redemptionInfo);
    // var lines = getReadyLines(t, redemptionInfo);
    let lines = [];
    if (redemptionInfo.status === "not_ready") {
        lines = getNotReadyLines(t, redemptionInfo);
    } else if (redemptionInfo.status === "ready") {
        lines = getReadyLines(t, redemptionInfo);
    } else if (redemptionInfo.status === "redeemed") {
        lines = getRedeemedLines(t, redemptionInfo);
    }
    // getReceivedLines(t, redemptionInfo);

    let qrCodeCard = getRedemptionQRCode(t, redemptionInfo, ageGroup);

    return (
        <Stack>
            <Box sx={style}>
                {
                    lines.map((line, idx) => {
                        return (
                            <Box key={idx} >
                                {line}
                            </Box>)
                    })
                }

            </Box>
            {qrCodeCard}
        </Stack>
    );
}
