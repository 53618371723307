import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { auth, updateAuthState, updateUserInfo } from "../reducers/userSlice";

// helper
import {
  getLocalStorageUser,
  removeLocalStorageUser,
} from "../helper/LocalStorageHelper";
// import { checkPagePermission } from "../helper/PermissionHelper";

const AuthProvider = ({ children, page, action }) => {
  const location = useLocation();
  // const outlet = useOutletContext();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const dispatch = useDispatch();
  // const authorization = useSelector(auth);

  console.log("page=", page);

  useEffect(() => {
    var user = getLocalStorageUser();
    if (!user) {
      setIsAuthenticated(false);
      dispatch(updateAuthState(false));
    }

    try {
      user = JSON.parse(user);
      setIsAuthenticated(true);
      dispatch(updateUserInfo(user));

      // checkPagePermission(page, action);
      console.log("page", page, "action", action);
    } catch (error) {
      console.log(error);
      removeLocalStorageUser();
      setIsAuthenticated(false);
      dispatch(updateAuthState(false));
    }
  }, [location]);

  if (!isAuthenticated) {
    if(page === "temi") {
      return <Navigate to="/temi/login" state={{ from: location }} />;  
    }
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default AuthProvider;
