import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";


function getBlockMarkers(data) {

    let markers = [];
    const [barW, barH] = [30, 40];
    const fontSize = 2;
    const decPlace = data.decimal === undefined ? 1 : data.decimal;

    if (data.bar !== undefined) {
        const [x, y] = data.ui.bar || [0, 0];
        markers.push(
            {
                type: "bar", value: data.bar, x, y, barWidth: barW, barHeight: barH, spacing: 2
            });
    }

    if (data.valueA !== undefined) {
        let value = parseFloat(data.valueA || 0);
        const valueStr = value.toFixed(decPlace);
        const [x, y] = data.ui.a || [0, 0];
        //const x = data.ui.aX || 0;
        markers.push(
            {
                type: "text", text: valueStr, x, y, fontSize: fontSize,
            });
    }

    if (data.valueA2 !== undefined) {
        let value = parseFloat(data.valueA2 || 0);
        const valueStr = value.toFixed(decPlace);
        let [x, y] = data.ui.a || [0, 0];
        y += 55;
        markers.push(
            {
                type: "text", text: valueStr, x, y, fontSize: fontSize,
            });
    }

    if (data.valueB !== undefined) {
        let value = parseFloat(data.valueB || 0);
        const valueStr = value.toFixed(decPlace);
        let [x, y] = data.ui.b || [0, 0];
        markers.push(
            {
                type: "text", text: valueStr, x, y, fontSize: fontSize,
            });
    }

    if (data.valueC !== undefined) {
        let value = parseFloat(data.valueC || 0);
        const valueStr = value.toFixed(decPlace);
        let [x, y] = data.ui.c || [0, 0];
        markers.push(
            {
                type: "text", text: valueStr, x, y, fontSize: fontSize,
            });
    }

    // let markers = [];
    // let block = data.block || 0;
    // let blockRatio = ratio(block, 0, 3.0);
    // let x = lerp(115, 596, blockRatio);
    // markers.push({
    //     type: "dot", x: x, y: 0, r: 10, color: "transparent",
    //     stroke: "blue", strokeWidth: 4,
    //     origin: "center"
    // });
    return markers;
}

export default function TanitaMetabolismCard({ data }) {
    const image = {
        w: 1024, h: 627,
        src: "/assets/tanita/Metabolism_Fitness_Age.png"
    }

console.debug("data", data);


    const block1 = {
        valueA: data.bmr_kj,
        valueA2: data.bmr_kcal,
        decimal: 0, 
        bar: data.bmr_score,
        ui: {
            bar: [484, 71],
            a: [283, 71],
        }
    };


    const block2 = {
        valueA: data.visceral_fat_rating,
        bar: clamp(data.visceral_fat_rating, 0, 21),
        ui: {
            bar: [484, 224],
            a: [310, 245],
        }
    };

    const block3 = {
        valueA: data.tbw,
        valueA2: data.tbw_p,
        valueB: data.ecw,
        valueC: data.icw,
        ui: {
            a: [310, 358],
            b: [610, 388],
            c: [870, 388],
        }
    };

    const block4 = {
        valueA: data.ecw_p,
        bar: ratio(data.ecw_p, 32, 48) * 16,
        ui: {
            a: [310, 528],
            bar: [484, 514],
        }
    };

    const barValue1 = 13;
    const barValue2 = 10;
    const barValue3 = 5;

    // const 

    const barW = 30;
    const barH = 40;

    const x1 = 100;





    const markers = [
        ...getBlockMarkers(block1),
        ...getBlockMarkers(block2),
        ...getBlockMarkers(block3),
        ...getBlockMarkers(block4),
        // { type: "rect", x: x, y: y, width: w, height: h, color: "green", },
        // {
        //     type: "text", x: x1, y: 203,
        //     fontSize: 2, text: block1.value1.toFixed(1), color: "black",
        //     origin: "bottom-left"
        // },
        // { type: "bar", value: block1.bar, x: 484, y: 71, barWidth: barW, barHeight: barH, spacing: 2 },


        // { type: "bar", value: barValue2, x: 484, y: 224, barWidth: barW, barHeight: barH, spacing: 2 },
        // { type: "bar", value: barValue3, x: 484, y: 514, barWidth: barW, barHeight: barH, spacing: 2 },
        // {
        //     type: "rect", 10: x, y: y, width: w, height: h, color: "green",
        //     stroke: "black", strokeWidth: 5,
        // },
        //{ type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];

    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />
        </Stack>
    </Card>
}