import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";

function formatInfo(value) {
    const valueStr = parseFloat(value).toFixed(1);

    return `${valueStr}kg`;
}

export default function TanitaMuscleRadarCard({ data }) {
    const image = {
        // 1012 × 480
        w: 1012, h: 456,
        src: "/assets/tanita/Muscle.png"
    }

    const infos = [
        formatInfo(data.trunk_muscle_mass),
        formatInfo(data.la_muscle_mass),
        formatInfo(data.ll_muscle_mass),
        formatInfo(data.rl_muscle_mass),
        formatInfo(data.ra_muscle_mass),
    ];

    const radarValues = [
        parseInt(data.trunk_muscle_score),
        parseInt(data.la_muscle_score),
        parseInt(data.ll_muscle_score),
        parseInt(data.rl_muscle_score),
        parseInt(data.ra_muscle_score),         
    ];

    const positions = [
        [166, 114],
        [60, 260],
        [105, 394],
        [690, 395],
        [743, 255],
    ];




    const markers = [];

    const radarSetting = {
        values: radarValues, valueRange: [-4, 4],
        radarCenter: [505, 275], radarRange: [60, 175]
    };

    markers.push(
        { type: "radar", ...radarSetting}, 
    );

    for (let i = 0; i < infos.length; i++) {
        const [x, y] = positions[i];

        markers.push({
            type: "text", x: x, y: y,
            text: infos[i], 
            fontSize: 1.8,
        });
    }
    // { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }

    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />
        </Stack>
    </Card>
}