import React from "react";
import { Box, Grid } from "@mui/material";
import BodyInfoCard from "./BodyInfoCard";

export default function BodyInfoList({ bodyInfoList }) {
  const cardList = bodyInfoList.map((bodyInfo, index) => {
    return (
      <Grid key={index} item xs={12} md={4} lg={3}>
        <BodyInfoCard bodyInfo={bodyInfo} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {cardList}
    </Grid>
  );
}
