import { LoadingButton } from "@mui/lab";
import { Card, Typography, Grid, TextField, Divider } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import { Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAppName, getBrandLoginLogo } from "../utils/const";
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import dayjs, { Dayjs } from 'dayjs';

// import Typography from '../styles/overrides/Typography';
import { useFormik } from "formik";

const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));
const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)",
}));

export default function LoginForm({ onSumbit }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const loginLogoImage = getBrandLoginLogo(); // "she_logo.png";

  useEffect(() => {
    if (formData === null) {
      return;
    }
    // console.log(formData);
    if (onSumbit !== undefined) {
      onSumbit(formData);
    }
  }, [formData]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    // validateOnMount: true,
    // validationSchema: LoginSchema,
  });

  const handleSubmit = () => {
    console.debug("handleOnSubmit");
    if (formik.values.email === "" && formik.values.password === "") {
      return;
    }

    setFormData(formik.values);
    // setLoading(true);
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <Grid container alignItems="center" px={2}>
      <Grid item sm={6} xs={12} order={{ xs: 2, sm: 1 }}>
        <Box align={"center"} p={3}>
          <Box
            component="img"
            alt="fitness"
            src="/assets/images/illustrations/fitness-2.png"
            sx={{
              borderRadius: "0.3rem",
              //   width: "100%",
              mt: { xs: 0, sm: 8 },
              aspectRatio: { xs: "16/9", sm: "auto " },
              objectFit: "cover",
              width: { xs: "50vw", sm: "100%" },
            }}
          />
        </Box>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        sm={6}
        xs={12}
        order={{ xs: 1, sm: 2 }}
      >
        <Box
          component="img"
          width="120px"
          alt="Logo"
          src={ `/${loginLogoImage}` }
          alignSelf="center"
        />
        {/* <Box
          component="img"
          width="120px"
          alt="SheLogo"
          src="/she_logo.png"
          alignSelf="center"
        /> */}

        <Stack>
          <Typography
            variant="h2"
            align="center"
            sx={{ fontWeight: "bold", pt: 1 }}
          >
            <i>{getAppName()}</i>
          </Typography>
        </Stack>
        <TextField
          fullWidth
          type="email"
          name="email"
          label="電郵 / 電話"
          variant="outlined"
          // onBlur={handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />

        <TextField
          fullWidth
          name="password"
          type="password"
          label="密碼"
          variant="outlined"
          // onBlur={handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
          // onChange={handleChange}
          // helperText={touched.password && errors.password}
          // error={Boolean(errors.password && touched.password)}
        />
        <Stack direction="row" alignItems="center" sx={{ my: 0 }}>
          <Typography variant="body2" align="center" sx={{ pr: 2 }}>
            忘記密碼?
          </Typography>
          <Button
            variant="text"
            sx={{ fontSize: "body2", my: 0, p: 0 }}
            onClick={() => navigate("/reset-password")}
          >
            點擊重設密碼
          </Button>
        </Stack>

        {/* <FlexBox gap={1} alignItems="center">
                            <Checkbox
                                size="small"
                                name="agreed"
                                id="agreed"
                                onChange={formik.handleChange}
                                checked={formik.values.agreed}
                                sx={{ padding: 0 }}
                            />

                            <Typography variant="body2">
                                I have read and agree to the terms of service.
                            </Typography>
                        </FlexBox> */}

        <LoadingButton
          type="submit"
          color="primary"
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 1 }}
          fullWidth
        >
          登入帳號
        </LoadingButton>
        <Box width="100%">
          <Divider sx={{ my: 1 }} />
        </Box>

        <Button
          type="submit"
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleRegister}
        >
          創建帳號
        </Button>

        {/* </form> */}
      </Grid>
    </Grid>
  );
}
