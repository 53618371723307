import { Navigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import EventCard from "../components/EventCard";
import MemberEventInfoCard from "../components/MemberEventInfoCard";
import MemberEventSkillInfoList from "../components/MemberEventSkillInfoList";
import EventDashboardSection from "../sections/EventDashboardSection";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  Button,
  Card,
  Stack,
  Typography,
  Alert,
  //   Box,
  //   Typography,
  //   TextField,

  //   Grid,
  //   Card,
  //   CardActions,
  //   CardContent,
  //   FormGroup,
} from "@mui/material";
import { MemberApi } from "../api/MemberApi";
import { useDispatch } from "react-redux";
import { updateAuthState } from "../reducers/userSlice";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import EventList from "../components/EventList";
import ReadOnlyDialog from "../components/questionnaire/ReadOnlyDialog";
import TemiFunctionsSection from "../sections/TemiFunctionsSection";
import EventRedemptionSection from "../sections/EventRedemptionSection";

const kShowHealthQuestionnaire = false;

export default function Component() {
  const { member_event_id } = useParams();
  const [memberEventInfo, setMemberEventInfo] = useState(null);
  const [error, setError] = useState(null);
  const [questionnaireStatus, setQuestionnaireStatus] = useState("");
  const [redemptionInfo, setRedemptionInfo] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get('mode');
  

  const navigate = useNavigate();

  const eventMode = mode === undefined ? "checkin" : mode;
  console.log("eventMode=", eventMode);

  const isCheckIn = eventMode === "checkin" ? true : false;

  // const showEventInfoDefault = false;
  const showEventInfoDefault = true;

  useEffect(() => {
    loadEventRedemptionInfo();
  }, [memberEventInfo]);

  useEffect(() => {
    loadEventDetail();
    // loadEventRedemptionInfo();
  }, []);

  const getQRCode = () => {
    if (memberEventInfo === null) {
      return "";
    }
    var member = memberEventInfo?.member;
    if (member === null) {
      return "";
    }
    return member.qrcode_id;
  }

  const loadEventRedemptionInfo = async () => {
    if (memberEventInfo === null || memberEventInfo === undefined) {
      return;
    }
    let memberID = memberEventInfo?.member_id;
    let eventID = memberEventInfo?.event_id;

    try {
      const result = await MemberApi.getEventRedemptionInfo(memberID, eventID);
      console.debug("result: ", result);
      setRedemptionInfo(result.redeem_status);
    } catch (error) {
      console.error("loadEventRedemptionInfo: error=", error);
      setError(error);
    }
  }

  // member_event_id = 23;
  const loadEventDetail = async () => {
    // const memberID = 1;
    try {
      const result = await MemberApi.memberEventInfo(member_event_id);
      console.debug("result: ", result);
      setMemberEventInfo(result);
      setQuestionnaireStatus(result?.questionnaire_status);
      //setQuestionnaireStatus('disallow');
    } catch (error) {
      console.error(error);
      setError(error);
    }

    // setEventList(result);
  };

  const questionnaire = memberEventInfo?.questionnaire;

  // questionnaireStatus === null || questionnaireStatus === 'not_started' ? true: false;
  // let needInputQuestionnaire = false;
  // let questionnaireID = 0; // show form
  // let questionnaireID = 1;

  let onClickQuestionnaire = () => {
    console.debug("onClickQuestionnaire");
    navigate(`/questionnaire/${member_event_id}`);
  };

  let onClickSubmittedQuestionnaire = () => {
    console.debug("onClickSubmittedQuestionnaire");
    navigate(`/questionnaire-readonly/${member_event_id}`);
  };

  const [showEventInfo, setShowEventInfo] = useState(showEventInfoDefault);
  useEffect(() => {
    if (questionnaireStatus === "allow") {
      setShowEventInfo(() => true);
    }
  }, [questionnaireStatus]);

  const QuestionBlock = ({ state = null }) => {
    if (state === "not_start")
      return (
        <Button
          variant="contained"
          sx={{ width: { xs: "100%", sm: "auto" } }}
          onClick={onClickQuestionnaire}
        >
          填寫健康狀況問卷{" "}
        </Button>
      );
    if (state === "allow") {
      return (
        <>
          <ReadOnlyDialog formData={questionnaire.form_data} />
          <Button
            variant="contained"
            sx={{ width: { xs: "100%", sm: "auto" } }}
            onClick={onClickQuestionnaire}
          >
            再填寫健康狀況問卷
          </Button>
        </>
      );
    }

    if (questionnaireStatus === "disallow") {
      return (
        <>

          <Alert severity="warning" sx={{ width: "100%" }}>
            請諮詢我們的工作人員了解你的情況
          </Alert>
          <ReadOnlyDialog formData={questionnaire.form_data} />
          <Button
            variant="contained"
            sx={{ width: { xs: "100%", sm: "auto" } }}
            onClick={onClickQuestionnaire}
          >
            再填寫健康狀況問卷
          </Button>
        </>
      );
    }
    return <></>;
  };


  const QuestionSection = () => {
    if (kShowHealthQuestionnaire === false) {
      return <></>;
    }

    return (
      <Card sx={{ mb: 3, px: 3, py: 3 }}>
        {/* {questionnaireStatus} */}
        <Typography variant="h2" align="center">
          填寫健康狀況問卷
        </Typography>
        {questionnaireStatus === "not_start" ? (
          <Typography variant="h5" color={"#AB0362"} mt={1} align="center">
            必須填寫問卷以參加活動
          </Typography>
        ) : null}
        {questionnaireStatus === "disallow" ? (
          <Typography variant="h5" color={"#AB0362"} mt={1} align="center">
            你暫時不能參與我們體育運動
          </Typography>
        ) : null}

        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"center"}
          alignItems={"center"}
          p={2}
          mt={2}
          gap={2}
        >
          <QuestionBlock state={questionnaireStatus} />
        </Grid>
        {/* {questionBlock} */}
      </Card>
    );
  }

  const TemiHeading = () => {
    const appName = "Smart Gym App - " +
      (isCheckIn ? "Check-in" : "Check-out");

    return (
      <Typography variant="h2" align="left">
        <i>{appName}</i>
      </Typography>
    );
  }

  return (

    <Stack>
      <Grid container alignItems="center" sx={{ paddingTop: 1 }}>
        <Grid item xs={6}>
          <TemiHeading />
        </Grid>
        <Grid item xs={6}>
          <TemiFunctionsSection sx={{ mb: 2 }}
            mode={eventMode}
            memberEventInfo={memberEventInfo}
            redemptionInfo={redemptionInfo}
          />

        </Grid>
      </Grid>


      {memberEventInfo ? (
        <Stack direction="column">
          <pre>
            {/* QRCode: {getQRCode()}---
            {JSON.stringify(memberEventInfo, null, 2)} */}

            {/* { JSON.stringify(redemptionInfo, null, 2) } */}
          </pre>

          {/* <EventCard event={memberEventInfo.event} hideAction={true} /> */}
          {
            redemptionInfo && <EventRedemptionSection redemptionInfo={redemptionInfo} />
          }

          <QuestionSection />


          {showEventInfo && (
            <>

              <MemberEventInfoCard memberEventInfo={memberEventInfo} />
              <EventDashboardSection memberEventInfo={memberEventInfo} />
              {/* <pre>
                {JSON.stringify(memberEventInfo, null, 2)}
              </pre> */}
            </>
          )}
        </Stack>
      ) : (
        <>
          {error ? (
            <Typography variant="h5">Error: {error}</Typography>
          ) : (
            <Typography variant="h5">Loading...</Typography>
          )}
        </>
      )}
      {/* <Box component="pre">
        {questionnaireStatus}
        ---
        {JSON.stringify(memberEventInfo, null, 2)}
      </Box> */}
    </Stack>
  );
}
