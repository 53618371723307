import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";

import { useState } from "react";
import { MemberApi } from "../../../api/MemberApi";
// Question
import BaseInformation from "../BaseInformation";
import ParqQuestion from "../ParqQuestion";
import StatementQuestion from "../StatementQuestion";
import Advice from "../Advice";
import Thankyou from "../Thankyou";
import { useNavigate } from "react-router-dom";

// Formik
import { useFormik } from "formik";
import FormContent from "./questionFromSchema.jsx";

// User Session
import {
  getLocalStorageUser,
  //removeLocalStorageUser,
} from "../../../helper/LocalStorageHelper";

// Questions Schema
import ParqFormContent from "../ParqQuestion/parqFormSchema.jsx";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

export default function Component({ eventID = -1 }) {
  const [activeStep, setActiveStep] = useState(0);

  const [stepperProgress, setStepperProgress] = useState([0]);
  const navigate = useNavigate();
  // Language
  const { t } = useTranslation();

  const handleNext = (index) => {
    if (
      index === 0 &&
      (formik.values.age < 6 ||
        formik.values.age > 70 ||
        formik.values.isPregnant === true)
    ) {
      return setStepperProgress((previousArr) => [...previousArr, 4]);
    }

    if (index === 1) {
      for (const [key, value] of Object.entries(formik.values)) {
        if (key.startsWith("q") && value === 1) {
          return setStepperProgress((previousArr) => [...previousArr, 3]);
        }
      }
      // }
      // if (Object.values(formik.values).includes(1)) {
      //   console.log(Object.values(formik.values).includes(1));
      //   return setStepperProgress((previousArr) => [...previousArr, 3]);
      // }
    }

    if (index === 2) {
      return setStepperProgress((previousArr) => [...previousArr, 4]);
    }

    if (activeStep >= 4) return;

    setStepperProgress((previousArr) => [...previousArr, activeStep + 1]);

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // setIsValid(true);
    setStepperProgress((previousArr) => previousArr.slice(0, -1));

    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkIsValid = (index) => {
    if (index === 0 && formik.errors.age) {
      return true;
    }
    if (
      index === 0 &&
      formik.values.identity === "student_teacher" &&
      !formik.values.schoolName
    ) {
      return true;
    }
    //console.log(formik.errors);
    if (
      index === 1 &&
      Object.values(formik.errors).includes("請選擇一個選項")
    ) {
      return true;
    }

    if (
      index === 2 &&
      (formik.errors.name ||
        formik.errors.hkid ||
        formik.errors.witness ||
        formik.errors.selfConfirm ||
        formik.errors.witnessConfirm)
    )
      return true;
    // Valid
    return false;
  };

  useEffect(() => {
    // console.log(formik.isValid);
    setActiveStep((pre) => stepperProgress.at(-1));
  });

  const steps = {
    question: [
      {
        label: t("par_q_question.base_info"),
        content: BaseInformation,
      },
      {
        label: t("par_q_question.question"),
        content: ParqQuestion,
      },
      {
        label: t("par_q_question.statement"),
        content: StatementQuestion,
      },
      {
        label: t("par_q_question.advice"),
        content: Advice,
      },
      {
        label: t("par_q_question.confirm"),
        content: Thankyou,
        props: {
          stepperProgress: stepperProgress,
        },
      },
    ],
  };

  const NextButton = ({ index }) => {
    return (
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          handleNext(index);
        }}
        sx={{ mt: 1, mr: 1, fontWeight: 400 }}
        disabled={checkIsValid(index)}
      >
        {t("par_q_question.next")}
      </Button>
    );
  };

  const SubmitButton = () => {
    return (
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
        sx={{ mt: 1, mr: 1, fontWeight: 400 }}
      >
        {t("par_q_question.finish")}
      </Button>
    );
  };

  const ActionButtonSection = ({
    index = 0,
    maxStep = steps.question.length,
  }) => {
    return (
      <Box sx={{ mb: 2 }}>
        <Box>
          <Button
            size="small"
            variant="outlined"
            disabled={index === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1, fontWeight: 400 }}
          >
            {t("par_q_question.previous")}
          </Button>

          {index < maxStep - 1 ? (
            <NextButton index={index} />
          ) : (
            <SubmitButton />
          )}
        </Box>
      </Box>
    );
  };

  const formik = useFormik(FormContent.FormikInstance);

  // Get Member ID
  var user = getLocalStorageUser();
  user = JSON.parse(user);
  const currentMemberID = user ? user.id : 0; // useSelector(memberID);

  const handleSubmit = async () => {
    let formData = formatFormResult();
    // console.log(formData);

    try {
      await MemberApi.submitQuestionnaire(formData);

      // TODO route to the event main
      navigate(`/event-info/${eventID}`);
    } catch (error) {
      console.error("Question Submit: error=", error);
    }
  };

  const formatFormResult = () => {
    let result = JSON.parse(JSON.stringify(formik.values));
    let question = {};
    let answer = {};
    let allowSport = stepperProgress.includes(2);

    ParqFormContent.FormControl.forEach((_q) => {
      question[_q.name] = _q.question["zh-HK"];
      answer[_q.name] = result[_q.name];
      delete result[_q.name];
    });

    return {
      member_event_id: eventID,
      member_id: currentMemberID,
      question_data: {
        question,
        answer,
      },
      allowSport,
      ...result,
    };
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {/* Question Content */}

        {steps.question.map((_q, index) => {
          const Question = _q.content;
          return (
            <Step key={"q" + index}>
              <StepLabel>{_q.label}</StepLabel>
              <StepContent>
                <Question formik={formik} {...(_q.props || {})} />
                <Box my={2}>
                  <ActionButtonSection index={index} />
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
