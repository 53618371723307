import { Route, Navigate } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";
// import { getDashboardPageName } from "../helper/GlobalValueHelper";
import Home from "../views/Home";
import MemberEventInfo from "../views/MemberEventInfo";
import HomeLayout from "../layout/home/HomeLayout";
import HealthInfo from "../views/HealthInfo";
import Login from "../views/Login";
import ContactUs from "../views/ContactUs";
import Booking from "../views/Booking";
import BodyInfoList from "../views/BodyInfoList";
import HealthInfoList from "../views/HealthInfoList";

const route = {
    // element: <DashboardLayout />,    // TODO: MainLayout 
    path: '/',
    element: <HomeLayout />,
    children: [
        // Main Page 
        { element: <Navigate to="/home" />, index: true },
        {
            path: 'home',
            element:
                <AuthProvider page="Home" action={"view"} >
                    <Home />
                </AuthProvider >
        },
        // Member Event Info
        {
            path: 'event-info/:member_event_id',
            element: <AuthProvider page="MemberEventInfo" action={"view"}>
                <MemberEventInfo />
            </AuthProvider>
        },
        // HealthInfo
        {
            path: 'health',
            element: <AuthProvider page="HealthInfo" action={"view"}>
                <HealthInfo />
            </AuthProvider>
        },
        {
            path: "/body-info",
            element: <BodyInfoList />,
        },
        {
            path: "/health-info",
            element: <HealthInfoList />,
        },
        {
            path: 'contact-us',
            element: <ContactUs />
        },
        {
            path: 'booking',
            element: <Booking />
        }
        //Booking
    ],

};



export default route;