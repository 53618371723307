import {
    Typography,
    Box,
    Button,
    Dialog,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
// import { getLang } from "../helper/LangHelper";
import { getLang, localizedName } from "../helper/LangHelper";
import SkillRankTable from "./SkillRankTable";

export default function SkillScoreDialog({ skillInfo, gender = 'M', open, onClose }) {
    const { t } = useTranslation();


    const unit = skillInfo.skill.unit;
    const unitName = t(`unit.${unit}`);
    // unit; // '次';
    const skillName = localizedName(skillInfo.skill, "name");
    const fitnessName = localizedName(skillInfo.skill, "fitness_type");
    const skillRank = skillInfo.rank;
    const skillScoreStr = `${skillInfo.skill_score} ${unitName}`;

    // console.debug("confirmResult=", confirmResult);
    // const memberEventInfo = confirmResult.memberEventInfo;
    // const member = confirmResult.member;
    // const skillInfo = confirmResult.skillInfo;

    // memberEventInfo.skill = skillInfo;
    // memberEventInfo.created_at = (new Date()).toLocaleString();

    // const memberName = member.display_name;

    console.debug("skillInfo=", skillInfo);
    const onCancel = () => {
        console.debug("onCancel");
        onClose();
    }

    const rankTable = gender === 'M' ?
        skillInfo.skill.skill_rank_male
        : skillInfo.skill.skill_rank_female;



    return (
        <Dialog open={open} fullWidth scroll="body">
            <Card>
                <CardHeader title={fitnessName} action={
                    <Button onClick={onCancel}>關閉</Button>
                } />
                <CardContent>
                    <Stack>
                        <Typography>測試項目：{skillName}</Typography>
                        <Typography>等級：{skillRank}</Typography>
                        <Typography>結果：{skillScoreStr} </Typography>

                        <Divider sx={{ my: 2 }} />


                        <SkillRankTable rankTable={rankTable} 
                            rank={skillRank} unitName={unitName}/>
                            {/* <pre>
                               { JSON.stringify(skillInfo.skill, null, 2) }
                            </pre> */}
                    </Stack>
                    
                </CardContent>
            </Card>
        </Dialog>
    );
}