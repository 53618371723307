import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import { getCurrentAgeGroup } from '../../../helper/AccountHelper';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "0px 0.5px 4px rgba(0, 169, 176, 0.2)",

  background: theme.palette.background.paper,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

function AgeGroup({ ageGroup }) {

  var ageGroupText = "";
  if (ageGroup === "adult") {
    ageGroupText = "";
  } else if (ageGroup === "child") {
    ageGroupText = "";
  }

  if (ageGroupText === "") {
    return <> </>;
  }


  return (
    <Box sx={{ flexGrow: 1, paddingRight: 2 }}>
      <Typography variant="h6" noWrap color="primary"
        textAlign="right" >
        {ageGroupText}
      </Typography>
    </Box>
  )
}

// function getAgeGroup(savedAccount) {
//   if (savedAccount === null) {
//     return "adult";
//   }

//   return savedAccount.age_group;
// }

export default function Header({ onOpenNav }) {
  // const savedAccount = getStoredAccount();
  // console.debug("savedAccount: ", savedAccount);
  var ageGroup = getCurrentAgeGroup();
  console.debug("ageGroup: " + ageGroup);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <AgeGroup ageGroup={ageGroup} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >

          {/* <LanguagePopover />*/}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
