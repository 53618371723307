import {
  Grid,
  FormLabel,
  Box,
  Typography,
  Stack,
  Divider,
  Alert,
} from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";
export default function Component() {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box my={2}>
            <Alert severity="warning">{t("par_q_question.advice_alert")}</Alert>
          </Box>
          <FormLabel>{t("par_q_question.advice_msg_1")}</FormLabel>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" spacing={1}>
            <ArrowRightIcon />
            <Typography>{t("par_q_question.advice_msg_2")}</Typography>
          </Stack>
          <Box my={1} />
          <Stack direction="row" spacing={1}>
            <ArrowRightIcon />
            <Typography>{t("par_q_question.advice_msg_3")}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
