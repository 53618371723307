import {
    Container,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

export default function SimplePage() {
    const { t } = useTranslation();

    let lang = getLang();

    return (
        <Box>
            <Typography sx={{my:3}} variant="h3">Simple Page</Typography>
            
            <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec dui nunc mattis enim. Vulputate eu scelerisque felis imperdiet proin. Duis ultricies lacus sed turpis tincidunt id. Faucibus purus in massa tempor nec feugiat. Ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Nam libero justo laoreet sit. Egestas congue quisque egestas diam in. Tellus orci ac auctor augue. Luctus accumsan tortor posuere ac ut consequat semper viverra. Ullamcorper sit amet risus nullam eget felis eget nunc. Habitant morbi tristique senectus et netus et malesuada fames ac. Dignissim enim sit amet venenatis urna cursus. Aliquet sagittis id consectetur purus. Et pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Fusce id velit ut tortor pretium. Purus semper eget duis at tellus at. Tortor vitae purus faucibus ornare suspendisse sed. Eleifend quam adipiscing vitae proin.

            </Typography>

        </Box>
    )
}
