import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";

function formatInfo(value, percent) {
    const valueStr = parseFloat(value).toFixed(1);
    const percentStr = parseFloat(percent).toFixed(1);

    return `${percentStr}% / ${valueStr}kg`;
}

export default function TanitaFatRadarCard({ data }) {
    const image = {
        // 1012 × 480
        w: 1012, h: 480,
        src: "/assets/tanita/Fat.png"
    }

    // TODO: bind with data 
    const infos = [
        formatInfo(data.trunk_fat_mass, data.trunk_fat_p),
        formatInfo(data.la_fat_mass, data.la_fat_p),
        formatInfo(data.ll_fat_mass, data.ll_fat_p),
        formatInfo(data.rl_fat_mass, data.rl_fat_p),
        formatInfo(data.ra_fat_mass, data.ra_fat_p),
    ];
    const radarValues = [
        parseInt(data.trunk_fat_p_score), 
        parseInt(data.la_fat_p_score), 
        parseInt(data.ll_fat_p_score), 
        parseInt(data.rl_fat_p_score), 
        parseInt(data.ra_fat_p_score), 
    ]

    const positions = [
        [166, 124],
        [60, 270],
        [105, 404],
        [690, 405],
        [743, 265],

    ];

    const radarSetting = {
        values: radarValues, valueRange: [-4, 4],
        radarCenter: [505, 285], radarRange: [60, 175]
    };

    const markers = [];
    markers.push(
        { type: "radar", ...radarSetting}, 
    );

    for (let i = 0; i < infos.length; i++) {
        const [x, y] = positions[i];

        markers.push({
            type: "text", x: x, y: y,
            text: infos[i],
            fontSize: 1.8,
        });
    }
    // { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }

    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />

        </Stack>
    </Card>
}