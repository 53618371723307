import React from "react";
import { Card, Box, Button, Divider, Typography, Grid, CardContent, CardHeader } from "@mui/material";
import { localizedName } from "../helper/LangHelper";
import { timeToDateStr } from "../helper/StringHelper";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/system";
import { Table, TableRow, TableCell } from "@mui/material";

export default function HealthInfoCard({ info }) {
    // const infoData = bodyInfo.body_shape_data;

    // const infoTable = [];
    // for(const key in infoData) {
    //     infoTable.push(
    //         <TableRow key={key}>
    //             <TableCell component="th" scope="row">
    //                 {key}
    //             </TableCell>
    //             <TableCell align="right">{infoData[key]}</TableCell>
    //         </TableRow>
    //     );
    // }
    // for (const [key, value] of infoData.entries()) {
    //     console.log(`${key}: ${value}`); // prints "apple: red", "banana: yellow", "grape: purple"
    // }
    // for (const [key, value] of infoData.entries()) {
    //     console.log(`${key}: ${value}`); // prints "apple: red", "banana: yellow", "grape: purple"
    //     infoTable.push(
    //         <TableRow key={key}>
    //             <TableCell component="th" scope="row">
    //                 {key}
    //             </TableCell>
    //             <TableCell align="right">{value}</TableCell>
    //         </TableRow>
    //     );
    // };
    // infoData.map((row) => (
    //     <TableRow
    //         key={row.}
    //         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //     >
    //         <TableCell component="th" scope="row">
    //             {row.name}
    //         </TableCell>
    //         <TableCell align="right">{row.calories}</TableCell>
    //         <TableCell align="right">{row.fat}</TableCell>
    //         <TableCell align="right">{row.carbs}</TableCell>
    //         <TableCell align="right">{row.protein}</TableCell>
    //     </TableRow>
    // ))


    return (
        <Card variant="outlined" sx={{ mb: 3, pb: 3, borderRadius: "0.5rem" }}>

            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {info.event_name}
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" component="div">
                            <b>Height</b>: {info.height} cm
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" component="div">
                            <b>weight</b>: {info.weight} kg
                        </Typography>
                    </Grid>
                    
                </Grid>

                <Grid container pb={3}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" component="div">
                            <b>Eye Sight (Right)</b>: {info.eye_sight_r}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" component="div">
                            <b>Eye Sight (Left)</b>: {info.eye_sight_l}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" component="div">
                            <b>Eye Sight (Color)</b>: {info.eye_sight_color}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Typography variant="h5" pt={3}>
                    Tanita Screen Record
                </Typography>
                <Grid container p={3}>
                    {
                        info.tanita_info.map((item, index) => {
                            return (
                                <Grid item xs={12} md={6}>
                                    <Box
                                        component="img"
                                        backgroundColor="black"
                                        sx={{ width: "100%", objectFit: "contain" }}
                                        src={item}
                                    />
                                </Grid>
                            );
                        })
                    }
                </Grid>

                <Divider />

                <Typography variant="h5" pt={3}>
                    Balance Screen Record
                </Typography>
                <Grid container p={3}>
                    {
                        info.balance_info.map((item, index) => {
                            return (
                                <Grid item xs={12} md={6}>
                                    <Box
                                        component="img"
                                        backgroundColor="black"
                                        sx={{ width: "100%", objectFit: "contain" }}
                                        src={item}
                                    />
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </CardContent>
            {/* <Box
                component="img"
                backgroundColor="black"
                sx={{ maxHeight: 150, width: "100%", objectFit: "contain" }}
                src={bodyInfo.body_shape_image}
            />
            <Box sx={{ p: 2 }}>
                <Typography variant="h5" align="center">
                    記錄時間：{bodyInfo.created_at}
                </Typography>
            </Box>
            <Table size="small" aria-label="a dense table">
                {infoTable}
            </Table>


            { /* attribute list */}
            {/* <pre>
                {JSON.stringify(bodyInfo, null, 2)}
            </pre> */}
        </Card >
    );
}