import React from "react";
import { Card, Box, Button, Typography, Grid } from "@mui/material";
import { localizedName } from "../helper/LangHelper";
import { timeToDateStr } from "../helper/StringHelper";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/system";
import { Table, TableRow, TableCell } from "@mui/material";

export default function BodyInfoCard({ bodyInfo }) {
    const infoData = bodyInfo.body_shape_data;

    const infoTable = [];
    for(const key in infoData) {
        infoTable.push(
            <TableRow key={key}>
                <TableCell component="th" scope="row">
                    {key}
                </TableCell>
                <TableCell align="right">{infoData[key]}</TableCell>
            </TableRow>
        );
    }
    // for (const [key, value] of infoData.entries()) {
    //     console.log(`${key}: ${value}`); // prints "apple: red", "banana: yellow", "grape: purple"
    // }
    // for (const [key, value] of infoData.entries()) {
    //     console.log(`${key}: ${value}`); // prints "apple: red", "banana: yellow", "grape: purple"
    //     infoTable.push(
    //         <TableRow key={key}>
    //             <TableCell component="th" scope="row">
    //                 {key}
    //             </TableCell>
    //             <TableCell align="right">{value}</TableCell>
    //         </TableRow>
    //     );
    // };
    // infoData.map((row) => (
    //     <TableRow
    //         key={row.}
    //         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //     >
    //         <TableCell component="th" scope="row">
    //             {row.name}
    //         </TableCell>
    //         <TableCell align="right">{row.calories}</TableCell>
    //         <TableCell align="right">{row.fat}</TableCell>
    //         <TableCell align="right">{row.carbs}</TableCell>
    //         <TableCell align="right">{row.protein}</TableCell>
    //     </TableRow>
    // ))


    return (
        <Card variant="outlined" sx={{ mb: 3, pb: 3, borderRadius: "0.5rem" }}>
            <Box
                component="img"
                backgroundColor="black"
                sx={{ maxHeight: 150, width: "100%", objectFit: "contain" }}
                src={bodyInfo.body_shape_image}
            />
            <Box sx={{ p: 2 }}>
                <Typography variant="h5" align="center">
                    記錄時間：{bodyInfo.created_at}
                </Typography>
            </Box>
            <Table size="small" aria-label="a dense table">
                {infoTable}
            </Table>


            { /* attribute list */}
            {/* <pre>
                {JSON.stringify(bodyInfo, null, 2)}
            </pre> */}
        </Card >
    );
}