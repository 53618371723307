import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";

// import { useFormik } from "formik";
import { useEffect } from "react";
import FormContent from "./parqFormSchema.jsx";

import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";

export default function Component({ setIsValid = () => {}, formik }) {
  // init formik
  // const formik = useFormik(FormContent.FormikInstance);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setIsValid(formik.isValid);
  }, [formik.isValid]);

  return (
    <>
      <Grid container spacing={2}>
        {FormContent.FormControl.map((_q, index) => (
          <Grid item xs={12} key={_q.name}>
            <FormControl variant="standard">
              <FormLabel id="demo-error-radios">
                {_q.question[i18n.language]}
              </FormLabel>
              <RadioGroup
                name={_q.name}
                value={formik.values[_q.name]}
                onChange={(event) => {
                  formik.setFieldValue(
                    _q.name,
                    parseInt(event.currentTarget.value)
                  );
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("par_q_question.yes")}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={t("par_q_question.no")}
                />
              </RadioGroup>
              {/* Error MSG */}
              {/* <FormHelperText>{123}</FormHelperText> */}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
