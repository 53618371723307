import React from "react";
import { Card, Box, Button, Typography, Grid } from "@mui/material";
import { localizedName } from "../helper/LangHelper";
import { timeToDateStr } from "../helper/StringHelper";
import { useNavigate } from "react-router-dom";
import { MemberApi } from "../api/MemberApi";
import { useSelector, useDispatch } from "react-redux";
import { memberID, updateAuthState } from "../reducers/userSlice";
import { Stack } from "@mui/system";

export default function EventCard({ event, hideAction }) {
  // const lang = getLang();
  const navigate = useNavigate();

  const currentMemberID = useSelector(memberID);
  const eventID = event.id;

  const navigateToMemberEventInfo = (memberEventID) => {
    navigate(`/event-info/${memberEventID}`);
  };

  const onEnroll = async (event) => {
    console.debug("onEnroll: clicked", event);

    try {
      const result = await MemberApi.enrollMemberEvent(
        currentMemberID,
        eventID
      );
      const memberEventID = result.id;
      navigateToMemberEventInfo(memberEventID);
    } catch (error) {
      console.error(error);
    }
  };

  const onEnter = (event) => {
    console.debug("onEnter: clicked", event);
    const memberEventID = event.member_info.id;
    navigateToMemberEventInfo(memberEventID);
  };

  const eventName = localizedName(event, "name");

  const dateRange =
    timeToDateStr(event.start_at) + " - " + timeToDateStr(event.end_at);

  const hasEnroll = event.member_info !== null;

  let actionButton;

  if (hideAction === true) {
    actionButton = '';
  } else {
    actionButton = hasEnroll ? (
      <Button variant="contained" fullWidth onClick={() => onEnter(event)}>
        進入活動
      </Button>
    ) : (
      <Button variant="contained" fullWidth onClick={() => onEnroll(event)}>
        參與活動
      </Button>
    );
  }

  return (
    <Card variant="outlined" sx={{ mb: 3, pb: 3, borderRadius: "0.5rem" }}>
      {/* <Box
        component="img"
        sx={{ maxHeight: 150, width: "100%", objectFit: "cover" }}
        src="/assets/banner/banner-event-vtc.png"
      /> */}
      <Grid
        container
        direction="column"
        spacing={1}
        alignItems="center"
        justifyContent={"center"}
        p={2}
        pb={0}
      >
        <Typography variant="h2" align="center">
          {eventName}
        </Typography>

        <Box align="center" my={1}>
          <Typography variant="h5" component={"span"}>
            活動日期：
          </Typography>
          <Typography variant="h5" component={"span"} fontWeight={400}>
            {dateRange}
          </Typography>
        </Box>
        {
          (actionButton !== '') && <Box mt={2}>{actionButton}</Box>
        }
      </Grid>
    </Card>
  );
}
