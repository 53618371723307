// import MuiAppBar from "./MuiAppBar.js";
import MuiButton from "./MuiButton.js";
// import MuiCard from "./MuiCard";
// import MuiTable from "./MuiTable";
// import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiTextField from "./MuiTextField.js";
// import MuiTab from "./MuiTab.js";
// import MuiBox from "./MuiBox.js";
// import MuiInputLabel from "./MuiInputLabel.js";
// import MuiSelect from "./MuiSelect.js";

const components = {
  // MuiAppBar: MuiAppBar,
  MuiButton: MuiButton,
  // MuiCard: MuiCard,
  // MuiTable: MuiTable,
  // MuiOutlinedInput: MuiOutlinedInput,
  MuiTextField: MuiTextField,
  // MuiTab: MuiTab,
  // MuiBox: MuiBox,
  // MuiInputLabel: MuiInputLabel,
  // MuiSelect: MuiSelect,
};

export default components;
