import { Box, Container, Paper, Typography } from "@mui/material";
import QuestionHeader from "../components/questionnaire/QuestionHeader";
// import { memberID, updateAuthState } from "../reducers/userSlice";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   getLocalStorageUser,
//   //   removeLocalStorageUser,
// } from "../helper/LocalStorageHelper";
import QuestionContent from "../components/questionnaire/QuestionContent";
// React Router
import { useParams } from "react-router-dom";
// Language
import { useTranslation } from "react-i18next";

export default function Questionnaire(props) {
  const { t } = useTranslation();
  const { event_id } = useParams();
  // TODO: ken: Check out Redux problem !!
  //const dispatch = useDispatch();
  //   var user = getLocalStorageUser();
  //   user = JSON.parse(user);
  //   const currentMemberID = user ? user.id : 0; // useSelector(memberID);

  //   const handleSumbit = (questionnaire) => {
  //     console.debug("handleSumbit", questionnaire);
  //   }

  return (
    <>
      <Container maxWidth="md">
        {/* <pre>
          currentMemberID: {currentMemberID} {JSON.stringify(user)}{" "}
        </pre> */}
        <Paper elevation={3}>
          <Box mx={2} mt={5} mb={8} py={5} px={{ xs: 1, md: 3 }}>
            {/* Title */}

            <QuestionHeader
              title={t("par_q_question.title")}
              subtitle={t("par_q_question.subtitle")}
              tag={t("par_q_question.tag")}
              description={
                <>
                  <Typography mb={1}>
                    {t("par_q_question.description_1")}
                  </Typography>
                  <Typography mb={1}>
                    {t("par_q_question.description_2")}
                  </Typography>
                  <Typography mb={1}>
                    {t("par_q_question.description_3")}
                  </Typography>
                  <Typography>{t("par_q_question.description_4")}</Typography>
                </>
              }
            />
            <Box px={1} py={3}>
              {/* Question Content */}
              <QuestionContent eventID={parseInt(event_id)}></QuestionContent>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
