import React from "react";
import { Grid } from "@mui/material";
import EventCard from "./EventCard";
export default function EventList({ eventList }) {
  const cardList = eventList.map((event, index) => {
    return (
      <Grid key={index} item xs={12} md={6} >
        <EventCard event={event} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {cardList}
    </Grid>
  );
}
