import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import {clamp, lerp, ratio} from "../../helper/MathHelper";

export default function TanitaAgeFatCard({data}) {
    const image = {
        w: 711, h:531,
        src: "/assets/tanita/Age_Fat.png"
    }

    let age = data.age || 0;
    
    // age = Math.c
    let score = data.target_fat_p || 0;

    let xRatio = ratio(age, 20, 90);
    let yRatio = ratio(score, 60, 120); // clamp(fat, 0, 3.0) / 50.0;

    // age = age || 0;
    
    // // age = Math.c
    // fat = fat || 0;

    let ageRatio = ratio(age, 20, 90);
    let fatRatio = ratio(score, 0, 3.0); // clamp(fat, 0, 3.0) / 50.0;

    // let 
    let x = lerp(115, 596, ageRatio); // age 
    let y = lerp(70, 415, fatRatio); // fat
    
    const markers = [
        { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];
    
    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{maxWidth:900}}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers}/>
        </Stack>
    </Card>
}