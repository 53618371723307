import {
  Grid,
  TextField,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { useFormik } from "formik";

import { isError, getErrorMsg } from "../../../helper/FormHelper.jsx";

import { useTranslation } from "react-i18next";
import { getLang } from "../../../helper/LangHelper.jsx";

import schoolList from "./schoolList.json";
import areaList from "./areaList.json";
// import { toast } from "react-toastify";

export default function Component({ formik }) {
  // init formik
  // const formik = useFormik(FormContent.FormikInstance);
  const { t, i18n } = useTranslation();
  const lang = getLang(true);
  const FilteredSchoolList = schoolList[formik.values.schoolLevel].filter(
    (_item) => {
      // if (formik.values.schoolArea) {
      //   return true;
      // }

      return _item.area === formik.values.area;
    }
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Age */}
          <TextField
            id={"age"}
            label={t("par_q_question.age")}
            type={"number"}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.age}
            error={isError(formik, "age")}
            helperText={getErrorMsg(formik, "age")}
            InputProps={{
              inputProps: { min: 1, max: 90 },
            }}
            onKeyPress={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            fullWidth
          />
        </Grid>
        {/* Identity */}
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>{t("par_q_question.your_identity")}</FormLabel>
            <RadioGroup
              name={"identity"}
              value={formik.values["identity"]}
              onChange={(event) => {
                if (event.currentTarget.value === "student_teacher") {
                  formik.setFieldValue("schoolName", "");
                  formik.setFieldValue("otherSchoolName", "");
                  formik.setFieldValue("area", "");
                }
                formik.setFieldValue("identity", event.currentTarget.value);
              }}
            >
              <FormControlLabel
                value={"student_teacher"}
                control={<Radio />}
                label={t("par_q_question.student_teacher")}
              />
              <FormControlLabel
                value={"public"}
                control={<Radio />}
                label={t("par_q_question.public")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formik.values["identity"] === "student_teacher" ? (
          <>
            <Grid item xs={12}>
              <FormControl variant="standard">
                <FormLabel>{t("par_q_question.school_level")}</FormLabel>
                <RadioGroup
                  name={"schoolLevel"}
                  value={formik.values["schoolLevel"]}
                  onChange={(event) => {
                    //reset the school name to empty
                    formik.setFieldValue("schoolName", "");

                    //set the grade to 4 if the school level is primary school and current value is > 4
                    if (
                      event.currentTarget.value === "diploma" &&
                      formik.values.eduGrade > "4"
                    ) {
                      formik.setFieldValue("eduGrade", 4);
                    }

                    formik.setFieldValue(
                      "schoolLevel",
                      event.currentTarget.value
                    );
                  }}
                >
                  <FormControlLabel
                    value={"primary_school"}
                    control={<Radio />}
                    label={t("par_q_question.primary_school")}
                  />
                  <FormControlLabel
                    value={"secondary_school"}
                    control={<Radio />}
                    label={t("par_q_question.secondary_school")}
                  />
                  <FormControlLabel
                    value={"diploma"}
                    control={<Radio />}
                    label={t("par_q_question.diploma")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* School Area */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel sx={{ mb: 1 }}>
                  {t("par_q_question.school_area")}
                </FormLabel>

                <Select
                  id="area"
                  fullWidth
                  value={formik.values["area"]}
                  onChange={(event) => {
                    formik.setFieldValue("schoolName", "");
                    formik.setFieldValue("otherSchoolName", "");

                    formik.setFieldValue("area", event.target.value);
                  }}
                >
                  {areaList.map((_area, index) => (
                    <MenuItem key={`area-${index}`} value={_area["zh"]}>
                      {_area[lang]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* School Name */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel sx={{ mb: 1 }}>
                  {t("par_q_question.school_name") + " *"}
                </FormLabel>
                <Select
                  id={"schoolName"}
                  fullWidth
                  value={formik.values["schoolName"]}
                  onChange={(event) => {
                    // reset another school name
                    formik.setFieldValue("otherSchoolName", "");

                    formik.setFieldValue("schoolName", event.target.value);
                  }}

                  // onChange={handleChange}
                >
                  <MenuItem disabled value="" selected>
                    <em>{t("par_q_question.please_select")}</em>
                  </MenuItem>
                  {FilteredSchoolList.map((_school, index) => (
                    <MenuItem
                      key={`school_name-${index}`}
                      value={_school["zh"]}
                    >
                      {_school[lang]}
                    </MenuItem>
                  ))}
                  <MenuItem value={"其他"}>
                    {t("par_q_question.other_school_name")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Other School Name */}
            {formik.values["schoolName"] === "其他" ? (
              <Grid item xs={12}>
                <TextField
                  id={"otherSchoolName"}
                  label={t("par_q_question.other_school_name") + "*"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.otherSchoolName}
                  error={isError(formik, "otherSchoolName")}
                  helperText={getErrorMsg(formik, "otherSchoolName")}
                  fullWidth
                />
              </Grid>
            ) : null}
            {/* Grade */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel sx={{ mb: 1 }}>
                  {t("par_q_question.edu_grade")}
                </FormLabel>
                <Select
                  id={"eduGrade"}
                  fullWidth
                  value={formik.values["eduGrade"]}
                  onChange={(event) => {
                    formik.setFieldValue("eduGrade", event.target.value);
                  }}
                  // onChange={handleChange}
                >
                  {[
                    ...Array(formik.values.schoolLevel === "diploma" ? 4 : 6),
                  ].map((_g, index) => (
                    <MenuItem key={`eduGrade-${index}`} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12}>
          <FormLabel>{t("par_q_question.isPregnant")}</FormLabel>

          <RadioGroup
            name={"isPregnant"}
            value={formik.values["isPregnant"]}
            onChange={(event) => {
              formik.setFieldValue(
                "isPregnant",
                event.currentTarget.value === "true" ? true : false
              );
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("par_q_question.yes")}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("par_q_question.no")}
            />
          </RadioGroup>
          {/* <Checkbox
            id={"isPregnant"}
            checked={formik.values.isPregnant}
            onChange={formik.handleChange}
          /> */}
        </Grid>
      </Grid>
    </>
  );
}
