import { LoadingButton } from "@mui/lab";
import {
  Card,
  Typography,
  MobileDatePicker,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import {
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import { useState, useEffect } from "react";
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import dayjs, { Dayjs } from 'dayjs';

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { NavLink, useNavigate } from "react-router-dom";
// import Typography from '../styles/overrides/Typography';
import { useFormik } from "formik";

const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));
const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)",
}));

export default function RegisterForm({ onSumbit }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (formData === null) {
      return;
    }
    // console.log(formData);
    if (onSumbit !== undefined) {
      onSumbit(formData);
    }
  }, [formData]);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      dob: "2000-01-01",
      password: "",
      gender: "M",
      phoneNum: "",
      agreed: false,
    },
    // validateOnMount: true,
    // validationSchema: LoginSchema,
  });

  const handleOnSubmit = () => {
    console.debug("handleOnSubmit");
    if (
      formik.values.password === "" &&
      formik.values.username === ""
    ) {
      return;
    }

    setFormData(formik.values);
    // setLoading(true);
  };

  return (
    <Card className="card">
      <Grid container alignItems="center" px={0} pt={5} pb={3} gap={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            創建新帳號
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box p={4} component={Stack} gap={2} height="100%">
            <TextField
              fullWidth
              type="text"
              name="username"
              label="用戶名字"
              variant="outlined"
              value={formik.values.username}
              onChange={formik.handleChange}
            />

            <TextField
              fullWidth
              type="phone"
              name="phoneNum"
              label="聯絡電話"
              variant="outlined"
              // onBlur={handleBlur}
              value={formik.values.phoneNum}
              onChange={formik.handleChange}
            />

            <TextField
              fullWidth
              name="password"
              type="password"
              label="密碼"
              variant="outlined"
              // onBlur={handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              // onChange={handleChange}
              // helperText={touched.password && errors.password}
              // error={Boolean(errors.password && touched.password)}
            />


        <TextField
              fullWidth
              type="email"
              name="email"
              label="電郵 (選填）"
              variant="outlined"
              // onBlur={handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
            />


            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dob"
                label="出生日期"
                inputFormat="YYYY-MM-DD"
                value={formik.values.dob}
                onChange={(newValue) => {
                  formik.setValues({
                    ...formik.values,
                    dob: newValue.format("YYYY-MM-DD"),
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">性別</FormLabel>
              <RadioGroup
                row
                id="gender"
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik.values.gender}
                onChange={(e) => {
                  // console.debug("e=", e);
                  formik.setFieldValue("gender", e.target.value);
                }}
                name="radio-buttons-group"
              >
                <FormControlLabel value="M" control={<Radio />} label="男性" />
                <FormControlLabel value="F" control={<Radio />} label="女性" />
              </RadioGroup>
            </FormControl>

            {/* <FlexBox gap={1} alignItems="center">
                            <Checkbox
                                
                                name="agreed"
                                id="agreed"
                                onChange={formik.handleChange}
                                checked={formik.values.agreed}
                                sx={{ padding: 0 }}
                            />

                            <Typography variant="body2">
                                I have read and agree to the terms of service.
                            </Typography>
                        </FlexBox> */}

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              onClick={handleOnSubmit}
            >
              創建帳號
            </LoadingButton>

            <Box>
              已經有帳號了嗎？
              <NavLink
                to="/login"
                style={{ color: "primary.main", marginLeft: 5 }}
              >
                登入帳號
              </NavLink>
            </Box>
            {/* </form> */}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
