
export const timeToDateStr = (timeStr) => {
    const idx = timeStr.indexOf(" ");
    if (idx === -1) {
        return timeStr;
    }
    return timeStr.substring(0, idx);
};


export const timeToTimeStr = (timeStr) => {
    const idx = timeStr.indexOf(" ");
    if (idx === -1) {
        return timeStr;
    }
    return timeStr.substring(idx);
};

