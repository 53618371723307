// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { Container } from "@mui/system";
import { useFormik } from "formik";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";

import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  FormGroup,
  CardActions,
  Button,
} from "@mui/material";
import ValidationTextField from "../components/ValidationTextField";
import { MemberApi } from "../api/MemberApi";
import { isAllOf } from "@reduxjs/toolkit";
import LoginForm from "../components/LoginForm";

export default function Login() {
  const dispatch = useDispatch();


  const onLogin = async (values) => {
    console.debug("onSubmit: ", values);
    const email = values.email;
    const password = values.password;
    const result = await MemberApi.login(email, password);

    // result.jwt = null;
    let jwt = result.jwt;
    let userInfo = result.user;

    if (jwt === undefined || jwt == null) {
      // alert("Login failed");

      // ken: simplify the logic 
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "系統錯誤",
      });
      return;
    }

    if (userInfo === undefined || userInfo == null) {
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "系統錯誤",
      });
      return;
    }




    dispatch(updateUserInfo(userInfo));
    setLocalStorageUser(userInfo);
    console.debug("jwt: ", jwt);
    console.debug("userInfo: ", userInfo);

    // focus update the react app
    window.location.href = "/";
  }

  return (
    <Box>
      <LoginForm onSumbit={onLogin} />
    </Box>
  );
}
