import Components from "./components";
import Palette from "./palette.js";
import shadows from "./shadows";

const themeOptions = {
  palette: Palette,
  typography: {},
  components: Components,
  shadows: shadows(),

  // custom spacing
  //spacing: (factor) => `${factor >= 1 ? Math.pow(1.125, factor) : factor}rem`,
  //spacing: (factor) => `${factor >= 1 ? Math.pow(, factor) : factor}rem`,
};

export default themeOptions;
