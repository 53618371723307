import axios from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";


const register = async (registerData) => {
  const data = {...registerData};

  data.user_type = 'public';

  const response = await axios.post("/register", data);

  return ApiHelper.processResponse(response);
};


const login = async (email, password) => {
  const data = {
    email,
    password,
  };

  const response = await axios.post("/login", data);

  return ApiHelper.processResponse(response);
};


const resetPassword = async (account) => {
  const data = {
    account,
  };

  const response = await axios.post("/resetPassword", data);

  return ApiHelper.processResponse(response);
};


const logout = async (email, password) => {
  const data = {};

  const response = await axios.post("/logout", data);

  return ApiHelper.processResponse(response);
};

const bodyInfoList = async (memberID) => {
  const data = {};

  const url = `/memberBodyShapeInfo/memberInfo/${memberID}`;

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const eventList = async (memberID) => {
  const data = {};

  const url = `/memberEvents/${memberID}`;

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const enrollMemberEvent = async (member_id, event_id) => {

  const data = {
    member_id: member_id,
    event_id: event_id,
  };

  const url = `/enrollEvent`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
}

const memberEventInfo = async (memberEventID) => {
  const data = {
  };

  const url = `/memberEventInfo/show/${memberEventID}?fields=fullDetail`;

  const response = await axios.get(url, data);

  const result = ApiHelper.processResponse(response);
  if (result == null || result === undefined) {
    return null;
  }

  return result.record;
};

const submitQuestionnaire = async (formData) => {
  
  const url = `/submitQuestionnaire`;

  const response = await axios.post(url, formData);

  return ApiHelper.processResponse(response);
};



const lastMemberHealthData = async (memberID) => {
  const data = {};

  const url = `/lastMemberHealthData/${memberID}`;

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};


const getEventHealthInfo = async (memberID) => {
  const data = {};

  // {{host}}/api/memberEventHealthInfo/memberInfo?member_id=5762
  const url = `/memberEventHealthInfo/memberInfo?member_id=${memberID}`;

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};


const getEventRedemptionInfo = async (memberID, eventID) => {
  const data = {};

  // {{host}}/api/memberEventHealthInfo/memberInfo?member_id=5762
  const url = `/memberEventInfo/getEventRedemptionInfo?member_id=${memberID}&event_id=${eventID}`;

  const response = await axios.get(url, data);
  console.debug("DEBUG: getEventRedemptionInfo: response=", response);

  return ApiHelper.processResponse(response);
};


// const memberEventInfo = async (memberEventID) => {
//   const data = {
//   };

//   const url = `/memberEventInfo/show/${memberEventID}?fields=fullDetail`;

//   const response = await axios.get(url, data);

//   const result = ApiHelper.processResponse(response);
//   if(result == null || result === undefined){
//     return null;
//   }

//   return result.record;
// };


// const logout = async () => {
//   const data = {};

//   const response = await axios.post("/logout", data);
//   return response;
// };

// const list = async (page, pageSize, filters) => {
//   const query = {
//     filters: filters || undefined,
//     page: page || 1,
//     page_size: pageSize || 10,
//   };
//   const response = await axios.get(
//     `/user/query?query=${JSON.stringify(query)}`
//   );

//   const result = response.data.result;
//   return {
//     data: result.data.map((data) => {
//       return {
//         id: data.id,
//         username: data.username,
//         email: data.email,
//         role: data.role,
//       };
//     }),
//     page: result.page,
//     pageSize: result.page_size,
//     total: result.total,
//   };
// };

// const details = async (id) => {
//   const response = await axios.get(`user/show/${id}`);
//   const result = response.data.result;
//   return {
//     id: result.record.id,
//     username: result.record.username,
//     email: result.record.email,
//     phoneNumber: result.record.phone_number,
//     firstName: result.record.first_name,
//     lastName: result.record.last_name,
//     phoneNumber: result.record.phone_number,
//     joinDate: result.record.join_date,
//     roles: result.record.roles,
//   };
// };

// const create = async (user) => {
//   const data = {
//     username: user.username,
//     first_name: user.firstName,
//     last_mame: user.lastName,
//     email: user.email,
//     password: user.password != "" ? user.password : undefined,
//     phone_number: user.phoneNumber,
//     roles: user.roles ? user.roles.map((role) => role.value) : [],
//   };

//   const response = await axios.post(`user/create`, data);

//   return response.data.result.insert_id;
// };

// const update = async (id, user) => {
//   const data = {
//     username: user.username,
//     first_name: user.firstName,
//     last_mame: user.lastName,
//     email: user.email,
//     password: user.password != "" ? user.password : undefined,
//     phone_number: user.phoneNumber,
//     join_date: user.joinDate,
//     roles: user.roles ? user.roles.map((role) => role.value) : [],
//   };

//   const response = await axios.post(`user/update/${id}`, data);

//   return response.data.success;
// };

export const MemberApi = {
  login,
  logout,
  register,
  eventList,
  getEventRedemptionInfo,
  getEventHealthInfo,
  bodyInfoList,
  memberEventInfo,
  enrollMemberEvent,
  lastMemberHealthData,
  submitQuestionnaire,
  resetPassword,
  // list,
  // details,
  // create,
  // update,
};
