import * as React from "react";
import Container from "@mui/material/Container";
import { Box, Stack, Card, CardHeader, CardContent, Button, Link, Typography, Divider } from "@mui/material";


export default function Booking() {



  return (

    <Stack direction="column" gap={1}>
      <Box component="img" p={0} src="/assets/booking/booking.jpeg" sx={{ width: '100%' }} />



    </Stack>
  );

}
