import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import {clamp, lerp, ratio} from "../../helper/MathHelper";

export default function TanitaBasicHealthTableCard({data}) {
    const image = {
        w: 952, h:463,
        src: "/assets/tanita/Body_Composition_Analysis_Result_1.png"
    }

    // age = age || 0;
    
    // // age = Math.c
    // fat = fat || 0;

    // let ageRatio = ratio(age, 20, 90);
    // let fatRatio = ratio(fat, 0, 3.0); // clamp(fat, 0, 3.0) / 50.0;

    // // let 
    // let x = lerp(115, 596, ageRatio); // age 
    // let y = lerp(70, 415, fatRatio); // fat
    const fatWeight = 80.0;
    const bone = 28.4;

    const col1 = [
        parseFloat(data.weight), 
        parseFloat(data.fat_p),
        parseFloat(data.fat_mass),
        parseFloat(data.ffm),
        parseFloat(data.muscle_mass),
        parseFloat(data.bmi),
        parseFloat(data.metabolic_age),        
    ]
    const col2 = [
        '59.3-73.4',    '8.0-20.0', 
        '7.0-17.6',     '70.2-80.7', 
        '66.9-77.4',    '21.0-26.0',
    ]
    const col3 = [
        parseFloat(data.predicted_weight),
        parseFloat(data.target_fat_p),        
        parseFloat(data.predicted_fat_mass),
    ]
    const col4 = [
        parseFloat(data.weight) - parseFloat(data.predicted_weight),
        parseFloat(data.fat_p) - parseFloat(data.target_fat_p),
        parseFloat(data.fat_mass) - parseFloat(data.predicted_fat_mass),
    ]
    

    let yOffset; 
    yOffset = 48;
    const col1Markers = col1.map((value, index) => {
        return { type: "text", x: 300, y: 110 + yOffset*index, 
            fontSize:2, text: value.toFixed(1), color: "black"  
        }
    });

    const col2Markers = col2.map((value, index) => {
        return { type: "text", x: 460, y: 110 + yOffset*index, 
            fontSize:2, text: value, color: "black"  
        }
    });

    const col3Markers = col3.map((value, index) => {
        return { type: "text", x: 700, y: 110 + yOffset*index, 
            fontSize:2, text: value.toFixed(1), color: "black"  
        }
    });

    const col4Markers = col4.map((value, index) => {
        return { type: "text", x: 830, y: 110 + yOffset*index, 
            fontSize:2, text: value.toFixed(1), color: "black"  
        }
    });



    
    const markers = [
        ...col1Markers,
        ...col2Markers,
        ...col3Markers,
        ...col4Markers,
        // ...upperMarkers,
        // ...lowerMarkers,
        // { type: "text", x: 260, y: 50, fontSize:2, text: fatWeightStr, color: "black", origin: "" },
        // { type: "text", x: 260 + offset, y: 50, fontSize:2, text: boneStr, color: "black", origin: "" },
        
    //    { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];
    // weight	fat_p	fat_mass	ffm	muscle_mass
    // bmi 
// metabolic_age
// target_fat_p	predicted_weight	predicted_fat_mass
    
    
    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{maxWidth:900}}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers}/>
        </Stack>
    </Card>
}