import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";


export default function TanitaBodyType({ data }) {
    const image = {
        // 992 × 595
        w: 992, h: 595,
        src: "/assets/tanita/Body_Type.png"
    }

    let fatTotal = parseFloat(data.trunk_fat_p_score)
        + parseFloat(data.la_fat_p_score)
        + parseFloat(data.ll_fat_p_score)
        + parseFloat(data.rl_fat_p_score)
        + parseFloat(data.ra_fat_p_score);

    let massTotal = parseFloat(data.trunk_muscle_score)
        + parseFloat(data.la_muscle_score)
        + parseFloat(data.ll_muscle_score)
        + parseFloat(data.rl_muscle_score)
        + parseFloat(data.ra_muscle_score);

        // formatInfo(data.trunk_muscle_mass),
        // formatInfo(data.la_muscle_mass),
        // formatInfo(data.ll_muscle_mass),
        // formatInfo(data.rl_muscle_mass),
        // formatInfo(data.ra_muscle_mass),

    //massTotal = 0;
    //fatTotal = 20;
    console.debug("massTotal", massTotal);
    console.debug("fatTotal", fatTotal);

    // let 
    let x = lerp(220, 946, ratio(massTotal, -20, 20));
    let y = lerp(124, 489, ratio(fatTotal, -20, 20));

    // parseInt(data.trunk_fat_p_score), 
    // parseInt(data.la_fat_p_score), 
    // parseInt(data.ll_fat_p_score), 
    // parseInt(data.rl_fat_p_score), 
    // parseInt(data.ra_fat_p_score), 

    const markers = [
        { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];

    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />
        </Stack>
    </Card>
}