// @mui
import { Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";

// ----------------------------------------------------------------------

export default function DashboardNumberCard({
  heading = null,
  number = 1,
  caption = null,
  subCaption = null,
  color = "primary",
  headingColor = "text.primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        py: 3,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color].main,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Stack direction="column">
        {heading && (
          <Typography sx={{ color: headingColor, fontSize: 14 }}>
            {heading}
          </Typography>
        )}

        <Typography sx={{ py: 1 }} variant="dashNum1">
          {number}
        </Typography>
        {caption && (
          <Typography
            sx={{ color: headingColor, fontSize: 14, fontWeight: "bold" }}
          >
            {caption}
          </Typography>
        )}
        {subCaption && (
          <Typography sx={{ color: headingColor, fontSize: 12 }}>
            {subCaption}
          </Typography>
        )}
      </Stack>
    </Card>
  );
}
