import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  Stack,
  FormHelperText,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";

// import { useFormik } from "formik";
import { useEffect } from "react";
// import FormContent from "./statementFormSchema.jsx";
import { isError, getErrorMsg } from "../../../helper/FormHelper.jsx";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Component({ setIsValid = () => {}, formik }) {
  // init formik
  // const formik = useFormik(FormContent.FormikInstance);
  const { t } = useTranslation();
  useEffect(() => {
    setIsValid(formik.isValid);
  }, [formik.isValid]);

  return (
    <>
      <Grid container spacing={2}>
      <Grid container gap={1}>
          
          <Grid item xs={12}>
            <Box sx={{pt:3}}>{t("par_q_question.ps")}</Box>
          </Grid>
          <Stack direction="row" spacing={1}>
            <ArrowRightIcon />
            <Typography variant="caption">
              {t("par_q_question.ps_msg_1")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <ArrowRightIcon />
            <Typography variant="caption">
              {t("par_q_question.ps_msg_2")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <ArrowRightIcon />
            <Typography variant="caption">
              {t("par_q_question.ps_msg_3")}
            </Typography>
          </Stack>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id={"name"}
            label={t("par_q_question.name")}
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={isError(formik, "name")}
            helperText={getErrorMsg(formik, "name")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id={"hkid"}
            label={t("par_q_question.hkid")}
            value={formik.values.hkid}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={isError(formik, "hkid")}
            helperText={getErrorMsg(formik, "hkid")}
            inputProps={{ maxLength: 5 }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: formik.values.age > 18 ? "none" : "block" }}
        >
          <TextField
            id={"witness"}
            label={t("par_q_question.witness")}
            disabled={formik.values.age > 18}
            value={formik.values.witness}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={isError(formik, "witness")}
            helperText={getErrorMsg(formik, "witness")}
            fullWidth
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <FormControl required error={isError(formik, "selfConfirm")}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="selfConfirm"
                    checked={formik.values.selfConfirm}
                    onChange={formik.handleChange}
                  />
                }
                label={t("par_q_question.self_confirm")}
              />
              <FormControlLabel
                sx={{ display: formik.values.age > 18 ? "none" : "block" }}
                disabled={formik.values.age > 18}
                control={
                  <Checkbox
                    id="witnessConfirm"
                    checked={formik.values.witnessConfirm}
                    onChange={formik.handleChange}
                  />
                }
                label={t("par_q_question.witness_confirm")}
              />
              <FormHelperText>
                {t("par_q_question.confirm_msg_1")}
                <br />
                {t("par_q_question.confirm_msg_2")}
              </FormHelperText>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
