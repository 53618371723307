import { Grid, Stack, Box } from "@mui/material";
import { locales } from "moment";
import { radarPoints } from "./MathHelper";


export const svgRadar = (setting, key) => {
    const values = setting.values || [];
    const valueRange = setting.valueRange || [0, 100];
    const radarCenter = setting.radarCenter || [0, 0];
    const radarRange = setting.radarRange || [0, 100];

    const points = radarPoints(values, valueRange, radarCenter, radarRange[0], radarRange[1]);
    setting.points = points;

    return svgPolygon(setting, key);
    //// let points = radarPoints([-4, -1, 2, 3, 4], [-4, 4], [505, 285], 60, 175);
}

export const svgPolygon = (setting, key) => {
    const points = setting.points || [];

    const fill = setting.color || "transparent";
    const stroke = setting.stroke || "black";


    let style = {};
    // fill:"yellow", stroke:"pink", strokeWidth:5}; // stroke:pink;stroke-width:5";
    if (fill !== '') {
        style.fill = fill;
    }
    if (stroke !== '') {
        style.stroke = stroke;
        style.strokeWidth = setting.strokeWidth || 3;
    }

    return <Box key={key} points={points} component="polygon" style={style} />

}

export const svgBar = (setting, key) => {
    const value = setting.value || 0;

    const startX = setting.x || 0;
    const startY = setting.y || 0;
    const barW = setting.barWidth || 50;
    const barH = setting.barHeight || 50;
    const spacing = setting.spacing || 10;
    // const barColor = setting.barColor || "red";

    let divList = [];
    for (let i = 0; i < value; i++) {
        let localSetting = { ...setting };
        localSetting.x = startX + (barW + spacing) * i;
        localSetting.y = startY;
        localSetting.width = barW;
        localSetting.height = barH;

        divList.push(svgRect(localSetting, key + "-" + i));
        // return svgRect(localSetting, key + "-" + i);
    }

    return divList;
}

export const svgRect = (setting, key) => {
    const x = setting.x || 0;
    const y = setting.y || 0;
    const w = setting.width || 10;
    const h = setting.height || 10;
    const fill = setting.color || "";
    const stroke = setting.stroke || "";
    const strokeWidth = stroke === '' ? 0 : setting.strokeWidth || 3;

    //  style="fill:blue;stroke:pink;stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9"
    let style = {};
    // fill:"yellow", stroke:"pink", strokeWidth:5}; // stroke:pink;stroke-width:5";
    if (fill !== '') {
        style.fill = fill;
    }
    if (stroke !== '') {
        style.stroke = stroke;
        style.strokeWidth = setting.strokeWidth || 3;
    }


    return <Box key={key} component="rect"
        x={x} y={y} width={w} height={h} fill={fill} style={style}
        stroke={stroke} strokeWidth={strokeWidth} />
}

export const svgCircle = (setting, key) => {
    const x = setting.x || 0;
    const y = setting.y || 0;
    const r = setting.r || 0;
    const fill = setting.color || "red";
    const stroke = setting.stroke || "";
    const strokeWidth = stroke === '' ? 0 : setting.strokeWidth || 3;


    return <Box key={key} component="circle"
        cx={x} cy={y} r={r} fill={fill}
        stroke={stroke} strokeWidth={strokeWidth} />
};

export const adjustAllSettingPos = (settings, width, height) => {
    return settings.map((setting) => {
        return adjustSettingPos(setting, width, height);
    });
}

const adjustSettingPos = (inputSetting, width, height) => {
    let setting = { ...inputSetting };
    let x = setting.x || 0;
    let y = setting.y || 0;

    // console.debug("setting=", setting);

    const origin = setting.origin || "top-left";    // default (top-left) 
    // console.debug("origin=", origin, " y=", y, " height=", height);
    if (origin === 'center') {
        y = height / 2 - y;
        x = width / 2 + x;
    } else if (origin === 'bottom-left') {
        y = height - y;
    }
    // console.debug("**origin=", origin, " y=", y, " height=", height);

    setting.x = x;
    setting.y = y;

    return setting;
}

export const svgText = (setting, key) => {
    const textStr = setting.text || "TEXT";
    const fill = setting.color || "black";

    const fontSize = setting.fontSize || 1;
    const valign = setting.valign || "top";
    const align = setting.align || "left";
    let x = setting.x || 0;
    let y = setting.y || 0;

    const fontSizeStr = `${fontSize}rem`;

    if (valign === "top") {
        y = y + fontSize * 16;
    } else if (valign === "middle") {
        y = y + fontSize * 8;
    }

    // ken: need to calculate text width 
    // if(align === "right") {
    //     x = x - textStr.length * fontSize * 8;
    // }


    // else base, the default position 


    return <Box key={key} component="text" x={x} y={y} fontSize={fontSizeStr} fill={fill}>{textStr}</Box>
}