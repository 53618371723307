// import { useState } from "react";
import { Grid, Box } from "@mui/material";

import { Container } from "@mui/system";
import LoginSection from "../sections/LoginSection";

const BackGroundSet = () => {
  return (
    <>
      <Box
        position={"absolute"}
        top={40}
        left={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/sphere.png"}
        sx={{
          height: "50vw",
          maxHeight: "300px",
        }}
      />
      <Box
        position={"absolute"}
        top={0}
        left={"50%"}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/cube.png"}
        sx={{
          transform: "translate(-55%, 0)",
          height: "20vw",
          maxHeight: "150px",
        }}
      />
      <Box
        position={"absolute"}
        top={0}
        right={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/pyramid.png"}
        sx={{
          height: "50vw",
          maxHeight: "400px",
        }}
      />
      {/* Bottom */}
      <Box
        position={"absolute"}
        bottom={0}
        right={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/sphere_bottom.png"}
        sx={{
          height: "45vw",
          maxHeight: "280px",
        }}
      />
      <Box
        position={"absolute"}
        bottom={0}
        left={"50%"}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/cube_bottom.png"}
        sx={{
          transform: "translate(-50%, 0)",
          height: "20vw",
          maxHeight: "140px",
        }}
      />
      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/pyramid_bottom.png"}
        sx={{
          height: "30vw",
          maxHeight: "180px",
        }}
      />
    </>
  );
};
export default function Login() {
  return (
    <Box position={"relative"} height={`100vh`} overflow="hidden">
      <BackGroundSet />
      <Container>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <LoginSection />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
