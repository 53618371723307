import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      backgroundColor: Palette.background.paper,

      "label + &": {
        // marginTop: "1.5rem",

        color: Palette.text.secondary,
      },
      "& .MuiInputBase-input": {},
    },
  },
};

export default Element;
