
export const ratio = (value, min, max, positiveOnly = false) => {
    let ratio = (value - min) / (max - min);

    if (positiveOnly) {
        return Math.max(0, ratio);
    }

    return ratio;
}

export const clamp = (value, min, max) => {

    if (value < min) {
        return min;
    }
    else if (value > max) {
        return max;
    }

    return value;
};

// Get the linear interpolation between two value
export const lerp = (value1, value2, amount) => {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
}

export const radarPoints = (values, valueRange, center, lenMin=0, lenMax=10 ) => {
    const [cx, cy] = center;
    const count = values.length;

    const points = [];

    const startAngle = Math.PI / 2;
    
    let index = 0;
    for (let v of values) {

        let l = lerp(lenMin, lenMax, ratio(v, valueRange[0], valueRange[1]));
        let angle = 2 * Math.PI * index / count + startAngle;
        //let p1 = [cx + l * Math.cos(angle), cy - l * Math.sin(angle)];

        points.push(cx + l * Math.cos(angle));
        points.push(cy - l * Math.sin(angle));
        //, cy - l * Math.sin(angle));
        index++;
    }





    // l = 100;
    // angle = 2 * Math.PI * 1 / 5 + Math.PI / 2;
    // let p2 = [cx + l * Math.cos(angle), cy - l * Math.sin(angle)];

    return points;
}