import axios from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";



const login = async (email, password) => {
  const data = {
    email,
    password,
  };

  const response = await axios.post("/temi/login", data);

  return ApiHelper.processResponse(response);
};


export const TemiApi = {
  login,  
};
