
import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
// import account from '../../../_mock/account';
import { memberID, updateAuthState } from "../../../reducers/userSlice";
import mockAccount from '../../../_mock/account';
import { MemberApi } from "../../../api/MemberApi";
import { useSelector, useDispatch } from "react-redux";
import { removeLocalStorageUser } from "../../../helper/LocalStorageHelper";
import { getStoredAccount } from '../../../helper/AccountHelper';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: '主頁',
    icon: 'eva:home-fill',
  },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

function getAccountData() {
  const savedAccount = getStoredAccount();
  if (savedAccount === null || savedAccount === undefined) {
    return {
      displayName: "Guest",
      email: "",
      dob: "",
      ageGroup: "",
      age: 0,
      phoneNum: "",
      photoURL: "/assets/banner/icon/shape-avatar.svg",
    }
  }

  console.debug("savedAccount: ", savedAccount);

  const photoURL = savedAccount.gender == 'M' ? 'ic_male.svg' : 'ic_female.svg';


  return {
    displayName: savedAccount.display_name,
    email: savedAccount.email,
    phoneNum: savedAccount.phone_num,
    dob: savedAccount.dob,
    ageGroup: savedAccount.age_group,
    age: savedAccount.age,
    // photoURL: "/assets/icons/" + photoURL,
    // photoURL: "/assets/icons/shape-avatar.svg",
  }
  // return mockAccount;
}


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();

  let account = getAccountData();
  // const account = (savedAccount === null || savedAccount === undefined) ?
  // {
  //   displayName: "Guest",
  //   photoURL: "/static/mock-images/avatars/avatar_default.jpg"} : savedAccount;
  //   savedAccount = mockAccount;
  // }) {

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const onLogout = async () => {
    dispatch(updateAuthState(false));
    removeLocalStorageUser();
    await MemberApi.logout();
    window.location.href = "/login";
    // handleClose();
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          聯繫電話: {account.phoneNum}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            出生日期: {account.dob}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={onLogout} sx={{ m: 1 }}>
          登出
        </MenuItem>
      </Popover>
    </>
  );
}
