import PropTypes from 'prop-types';
import { Link, NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, ButtonBase, DisplayBlock, List, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Button from '../../styles/overrides/Button';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const navigate = useNavigate();


  const isExternal = path.toLowerCase().substring(0, 4) === 'http';



  const handleClickNav = () => {
    if (isExternal) {
      window.open(path, "_blank");
      return;
    } else {
      navigate(path);
    }
  }

  return (
    <StyledNavItem
      component={ButtonBase}
      onClick={handleClickNav}
      // to={"https://www.google.com"}
      // component={RouterLink}
      // to={pathhttps://www.google.com"
      sx={{
        'width': '100%',
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        mb: 3,
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}

    </StyledNavItem>
  );
}
