import typographyCommon from "./typographyCommon";
const mainFont = "Noto Sans TC";
const typographyEn = {
  fontFamily: [
    "HelveticaNeue",
    "HelveticaNeue-Light",
    "HelveticaNeue-Ultra-Light",
  ].join(","),
  // htmlFontSize: 10,
  // fontWeightBold: 900,
  banner: {
    fontSize: "2.6rem",
    // fontFamily: "HelveticaNeue-Ultra-Light",
    letterSpacing: "7.56px",

    height: "2.7rem",
    color: "#B60E22",
    textShadow: "0px 0px 10px #C61B02",
  },
  shadowBanner: {
    fontSize: "5.5rem",
    // fontFamily: "HelveticaNeue-Ultra-Light",
    color: "#C11b0E",
    textShadow: "1px 2px 5px #8c0f01",
  },
  h1: {
    fontSize: "5.5rem",
    fontWeight: 700,
    // fontFamily: "HelveticaNeue-Ultra-Light",
  },
  h2: {
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: mainFont,
  },
  h3: {
    fontSize: "2.8rem",
    // fontFamily: "HelveticaNeue-Ultra-Light",
  },
  h4: {
    fontSize: "1.8rem",
    // fontFamily: "HelveticaNeue-Ultra-Light",
  },
  h5: {
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: mainFont,
  },

  body1: {
    fontSize: "1rem",
    // fontFamily: "HelveticaNeue-Light",
  },
  body2: {
    fontSize: "0.9rem",
    // fontFamily: "HelveticaNeue-Light",
  },
  caption: {
    fontSize: "0.77rem",
    lineHeight: 1.65,
    letterSpacing: "0em",
  },
  button: {
    fontSize: "1rem",
    // fontFamily: "HelveticaNeue",
  },

  ...typographyCommon,
};

// const typography = setTypography();
export default typographyEn;
