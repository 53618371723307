export const setLocalStorageLocale = (lang) => {
  localStorage.setItem("lang", lang);
};

export const getLocalStorageLocale = () => {
  return localStorage.getItem("lang");
};

export const setLocalStorageUser = (userInfo) => {
  localStorage.setItem("user", JSON.stringify(userInfo));
};

export const getLocalStorageUser = () => {
  return localStorage.getItem("user");
};

export const removeLocalStorageUser = () => {
  localStorage.removeItem("user");
};
