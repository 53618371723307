
// return "temi://exit-webview?participantID=" 
// 						+ this.studentInfo.personalData.participantID
// 						+ "&type=" + this.type;

const generateTemiLink = (dataParams) => {
    let queryParams = [];

    for (let key in dataParams) {
        let value = dataParams[key];
        if (value === undefined || value === null) {
            continue;
        }
        // url encode the value 
        value = encodeURIComponent(value);

        queryParams.push(`${key}=${value}`);
    }

    let queryString = queryParams.join("&");

    return `temi://exit-webview?${queryString}`;
};

const generateCheckOutLink = (dataParams) => {
    let params = { status: "check-out", ...dataParams };

    return generateTemiLink(params);
}

const generateCheckInLink = (dataParams) => {
    let params = { status: "check-in", ...dataParams };

    return generateTemiLink(params);
}

const generateBackLink = (dataParams) => {
    let params = { status: "back", ...dataParams };

    return generateTemiLink(params);
}

const TemiHelper = {
    generateCheckOutLink,
    generateCheckInLink,
    generateBackLink,
};
export default TemiHelper;