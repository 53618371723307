import * as React from "react";
import Container from "@mui/material/Container";
import { Box, Stack, Card, CardHeader, CardContent, Button, Link, Typography, Divider } from "@mui/material";


export default function ContactUs() {
  const benefitImages = [
    // { src: "/assets/contact-us/banner.jpeg", },
    { src: "/assets/contact-us/benefits-1.jpeg", },
    { src: "/assets/contact-us/benefits-2.jpeg", }
  ];

  const benefitContents = benefitImages.map((image, index) => {
    return <Box component="img" p={0} src={image.src} sx={{ width: '100%' }} />
  });

  const leaflets = [
    // { src: "/assets/contact-us/banner.jpeg", },
    { src: "/assets/contact-us/leaflet-1.jpeg", },
    { src: "/assets/contact-us/leaflet-2.jpeg", }
  ];

  const leafletContents = leaflets.map((image, index) => {
    return <Box component="img" p={0} src={image.src} sx={{ width: '100%' }} />
  });

  const contacts = [
    // { src: "/assets/contact-us/banner.jpeg", },
    { src: "/assets/contact-us/contact-2.jpeg", },
    { src: "/assets/contact-us/contact-1.jpeg", }
  ];

  const contactContents = contacts.map((image, index) => {
    return <Box component="img" p={0} src={image.src} sx={{ width: 'auto', maxWidth:'100%' }} />
  });
  

  return (

    <Stack direction="column" gap={1}>
      <Box component="img" p={0} src="/assets/contact-us/banner.jpeg" sx={{ width: '100%' }} />

      <Card>
        <CardHeader title="BENEFITS"  />
        <CardContent>
          {benefitContents}
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader title="DETAILS"  />
        <CardContent>
          {leafletContents}
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="CONTACTS"  />
        <CardContent>
          {contactContents}
        </CardContent>
      </Card>


    </Stack>
  );

}
