// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { Container } from "@mui/system";
import { useFormik } from "formik";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";

import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  FormGroup,
  CardActions,
  Button,
} from "@mui/material";
import ValidationTextField from "../components/ValidationTextField";
import { MemberApi } from "../api/MemberApi";
import { isAllOf } from "@reduxjs/toolkit";
import LoginForm from "../components/LoginForm";
import SkillRankCard from "../components/SkillRankCard";
import CareerRadarChart from "../components/CareerRadarChart";
import { getCurrentAgeGroup } from "../helper/AccountHelper";




function getMemberRadarEvents(eventInfo, ageGroup) {
  var event = eventInfo.event;
  if (event == null) {
    return null;
  }

  var skillSetting = event.skill_setting;
  if (skillSetting == null) {
    return null;
  }

  var skillList = skillSetting[ageGroup];
  if (skillList == null) {
    return null;
  }

  return skillList;
}


export default function EventDashboardSection({ memberEventInfo }) {
  
  var ageGroup = getCurrentAgeGroup();
  var radarEvents = getMemberRadarEvents(memberEventInfo, ageGroup);



  const infoList = memberEventInfo.skillInfoList;
  if(infoList === null || infoList === undefined){
    console.error("infoList is null");
    return <Box>empty skillInfoList</Box>
  }

  // Remove non-played skill info
  const filteredList = infoList.filter((info) =>
    info.participated !== false);

  const cardList = filteredList.map((info, index) => {
    if (info == null) {
      return <></>
    }
    return (
      <Grid item xs={6} key={index}>
        <SkillRankCard skillInfo={info} index={index} ></SkillRankCard>
      </Grid>
    )
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">E+職業導航</Typography>   
      </Grid>
      <Grid item xs={12}>
        <CareerRadarChart skillList={memberEventInfo.skillInfoList} radarItems={radarEvents} />
        {/* <pre>
          {JSON.stringify(memberEventInfo.skillInfoList, null, 2)}
        </pre> */}
      </Grid>

      {cardList}

    </Grid>

  );
}
