// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { useState, useEffect } from "react";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";
import {
    getLocalStorageUser,
    removeLocalStorageUser,
} from "../helper/LocalStorageHelper";

import {
    Typography,
    Box,
    Stack,
    Grid,
    Card,
    CardContent,
    FormGroup,
    CardActions,
    Button,
} from "@mui/material";

import TanitaAgeFatCard from "../components/tanita/TanitaAgeFatCard";
import TanitaBasicHealthChartCard from "../components/tanita/TanitaBasicHealthChartCard";
import TanitaBasicHealthTableCard from "../components/tanita/TanitaBasicHealthTableCard";
import TanitaLegMuscleCard from "../components/tanita/TanitaLegMuscleCard";
import TanitaBodyType from "../components/tanita/TanitaBodyType";
import TanitaFatRadarCard from "../components/tanita/TanitaFatRadarCard";
import TanitaMuscleRadarCard from "../components/tanita/TanitaMuscleRadarCard";
import TanitaMuscleBalanceCard from "../components/tanita/TanitaMuscleBalanceCard";
import TanitaMetabolismCard from "../components/tanita/TanitaMetabolismCard";
import { MemberApi } from "../api/MemberApi";


export default function TanitaSection({memberID=0, autoLoad = true, initialData = null }) {
    const [loading, setLoading] = useState(false);
    const [tanitaData, setTanitaData] = useState(initialData);

    // var user = getLocalStorageUser();
    // user = JSON.parse(user);
    const currentMemberID = memberID; // user ? user.id : 0; // useSelector(memberID);


    useEffect(
        () => {
            if (autoLoad) {
                setLoading(true);
                loadLastHealthData();
            }
        }
        , []);

    const loadLastHealthData = async () => {
        console.debug("loadLastHealthData: clicked");

        try {
            const result = await MemberApi.lastMemberHealthData(currentMemberID);
            console.debug("result: ", result);
            setTanitaData(result);    
        } catch (error) {
        } finally {
            setLoading(false);
        }
        
        // setLoading(false);
    }

    // const healthData = tanitaData.health_data;

    if (loading) {
        return <Typography variant="h4">
            載入中 ...
        </Typography>
    }

    if (tanitaData === null) {
        return <Typography variant="h5" sx={{my:4}}>
            未有相關紀錄，請前往測量站，進行健康評估。
        </Typography>
    }

    let healthData = tanitaData.health_data;

    return <Stack direction="column" gap={4}>
        <Stack>
            <Typography variant="h5">詳細測量結果</Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaBasicHealthTableCard data={healthData} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaBasicHealthChartCard data={healthData} />
                </Grid>
            </Grid>
        </Stack>

        <Stack>
            <Typography variant="h5">基礎代謝/內臟脂肪指數/體水份量</Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaMetabolismCard data={healthData} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaBodyType data={healthData} />
                </Grid>
            </Grid>
        </Stack>


        <Stack>
            <Typography variant="h5">部位測量分析</Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaMuscleRadarCard data={healthData} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TanitaFatRadarCard data={healthData} />
                </Grid>
            </Grid>
        </Stack>
        <Stack>
            <Typography variant="h5">肌肉平衡分析</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                    <TanitaMuscleBalanceCard data={healthData} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TanitaLegMuscleCard data={healthData} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TanitaAgeFatCard data={healthData} />
                </Grid>
            </Grid>
        </Stack>
    </Stack>
    // );

}
