import React from "react";
import { Box, Grid } from "@mui/material";
import HealthInfoCard from "./HealthInfoCard";

export default function HealthInfoList({ infoList }) {
  const cardList = infoList.map((info, index) => {
    return (
      <Grid key={index} item xs={12}>
        <HealthInfoCard info={info} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {cardList}
    </Grid>
  );
}
