import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  //   Box,
  //   Typography,
  //   TextField,

  //   Grid,
  //   Card,
  //   CardActions,
  //   CardContent,
  //   FormGroup,
} from "@mui/material";
import { MemberApi } from "../api/MemberApi";
import { useSelector, useDispatch } from "react-redux";
import { memberID, updateAuthState } from "../reducers/userSlice";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import EventList from "../components/EventList";

export default function Home() {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([]);

  const currentMemberID = useSelector(memberID);

  const loadEventList = async () => {
    if (currentMemberID === null) {
      return;
    }
    console.debug("currentMemberID: ", currentMemberID);
    const result = await MemberApi.eventList(currentMemberID);
    console.debug("result: ", result);
    setEventList(result);
  };

  useEffect(() => {
    loadEventList();
  }, [currentMemberID]);

  const onLogout = async () => {
    dispatch(updateAuthState(false));
    removeLocalStorageUser();
    await MemberApi.logout();
    window.location.href = "/login";
    // handleClose();
  };

  return (
    <Grid>
      {/* <Box>
          <Typography variant="h5">VTC Skill Passport</Typography>
        </Box>
        <Typography>
        currentMemberID: {currentMemberID}
        </Typography>
        <Box>
          <Button variant="outlined" onClick={() => {
            onLogout();
          }}>
            Logout
          </Button>
        </Box>
      </Grid> */}
      <Box>
        <EventList eventList={eventList} />
      </Box>
    </Grid>
  );
}
