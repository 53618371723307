

// import { getLang } from "../helper/LangHelper";
import { Grid, Stack, Box } from "@mui/material";
import { svgCircle, svgPolygon, svgBar, svgRect, svgText, adjustAllSettingPos, svgRadar } from "../helper/SvgHelper";
import { radarPoints } from "../helper/MathHelper";


export default function SvgDiagram({ image, markers }) {
    const imageW = image.w;
    const imageH = image.h;

    const viewBox = `0 0 ${imageW} ${imageH}`;

    const inputMarks = [...markers];

    // let settings = [
    //     // { type: "dot", x: 300, y: 150, r: 10, color: "blue", origin: "" },
    //     { type: "dot", x: 50, y: 50, r: 10, color: "blue", origin: "" },

    //     { type: "dot", x: 50, y: 50, r: 10, color: "red", origin: "bottom-left" },
    //     { type: "dot", x: 0, y: 0, r: 10, color: "yellow", origin: "center" },


    //     // { type: "text", x: 100, y: 112, fontSize:2, text: "AXXXXZ", color: "red", origin: "" },
    //     // { type: "text", x: 100, y: 112, fontSize:2, text: "AXXXXZ", color: "red", valign: "base" },
    //     // { type: "text", x: 400, y: 112, fontSize:2, text: "AXXXXZ", color: "red", valign: "middle" },
    //     // { type: "text", x: 250, y: 400, fontSize:2, text: "AXXXXZ", color: "red", valign: "base" },
    //     // { type: "text", x: 250, y: 400, fontSize:2, text: "AXXXXZ", color: "red", align:"center", valign: "base" },
    //     // { type: "text", x: 250, y: 400, fontSize:2, text: "AXXXXZ", color: "red", align:"right", valign: "base" },
    // ];

    let modifiedSettings = inputMarks
        ? adjustAllSettingPos(inputMarks, imageW, imageH)
        : [];

    //console.debug("SvgDiagram: modifiedSettings", modifiedSettings);


    let markerBoxs = modifiedSettings.map((setting, index) => {
        const type = setting.type;
        if (type === "text") {
            return svgText(setting, index);
        } else if (type === "rect") {
            return svgRect(setting, index);
        } else if (type === "bar") {
            return svgBar(setting, index);
        } else if (type === "polygon") {
            return svgPolygon(setting, index);
        } else if (type === "radar") {
            return svgRadar(setting, index);
        }
        return svgCircle(setting, index);
    });

    // export const svgRadar = (setting, key) => 
    // const testRadar = svgRadar(
    //     {
    //         values: [-4, -1, 2, 3, 4], valueRange: [-4, 4],
    //         radarCenter: [505, 285], radarRange: [60, 175]
    //     });

    // let points = radarPoints([-4, -1, 2, 3, 4], [-4, 4], [505, 285], 60, 175);

    // let [cx, cy] = [505, 273];
    // let l = 140;
    // let angle = 2 * Math.PI * 0 / 5 + Math.PI / 2;
    // let p1 = [cx + l * Math.cos(angle), cy - l * Math.sin(angle)];

    // l = 100;
    // angle = 2 * Math.PI * 1 / 5 + Math.PI / 2;
    // let p2 = [cx + l * Math.cos(angle), cy - l * Math.sin(angle)];

    // let testPolygon = svgPolygon({ points: [100, 200, 300, 200, 500, 600] });

    return <Stack>
        <Box component="svg" viewBox={viewBox} sx={{ width: '100%' }}>

            <Box id="base" component="image"
                href={image.src}
                x={0} y={0}
                width={imageW} height={imageH} />
            {markerBoxs}


            {/* <polygon points={[cx,cy,...p1,...p2,250,190,160,210]}
                style={{ fill: "transparent", stroke: "purple", strokeWidth: 3 }} /> */}

            {/* {testRadar} */}
            {/* {testPolygon} */}
            {/* <polygon points={points}
                style={{ fill: "transparent", stroke: "purple", strokeWidth: 3 }} /> */}

            {/* <Box component="text" x={0} y={50} fontSize="3rem" fill="red">Hello</Box> */}
        </Box>

        {/* <Box component="img" src="/assets/tanita/Age_Fat.png" sx={{ width: '100%' }}></Box> */}
    </Stack>

}