

export const getAppName = () => {
    return "Smart Gym App";
}

export const getBrandCode = () => {
    let brand = process.env.REACT_APP_BRAND;
    if (brand === undefined) {
        brand = "she";
    }
    return brand;
}

export const getBrandAppLogo = () => {
    let brand = getBrandCode();
    return `${brand}_logo.png`;
}


export const getBrandLoginLogo = () => {
    let brand = getBrandCode();
    return `${brand}_logo.png`;
}