
import { Container } from "@mui/system";
import { useFormik } from "formik";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";
import MockData from "../helper/MockData";

import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  FormGroup,
  CardActions,
  Button,
} from "@mui/material";
import ValidationTextField from "../components/ValidationTextField";
import { MemberApi } from "../api/MemberApi";
import { isAllOf } from "@reduxjs/toolkit";
import LoginForm from "../components/LoginForm";
import SkillRankCard from "../components/SkillRankCard";
import { getCurrentAgeGroup } from "../helper/AccountHelper";
import EventRedemptionInfo from "../components/EventRedemptionInfo";


export default function EventRedemptionSection({ redemptionInfo }) {

  if (redemptionInfo === null) {
    redemptionInfo = MockData.getEventRedemptionInfo();
  }

  var ageGroup = getCurrentAgeGroup();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EventRedemptionInfo redemptionInfo={redemptionInfo}
          ageGroup={ageGroup} />
      </Grid>
    </Grid>

  );
}
