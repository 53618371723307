// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: '＊最近活動',
    path: '/home',
    icon: icon('ic_home'),
  },

  // {
  //   title: '計劃介紹',
  //   path: 'https://sway.office.com/EyUqXrGBovvLSSP1?ref=Link',
  //   icon: icon('ic_home'),
  // },
  // {
  //   title: '智能運動會',
  //   path: 'https://sway.office.com/skwEfJf1srL2YGz0?ref=Link',
  //   icon: icon('ic_home'),
  // },
  // {
  //   title: '智能快車預約',
  //   // path: '/booking',
  //   path: 'https://docs.google.com/forms/d/e/1FAIpQLSfF4oZyfIX5AO5Ja9H4-tyFBL43NW_8qy1xvIslLNH1_vixRw/viewform',
  //   icon: icon('ic_home'),
  // },
  // {
  //   title: '過往活動',
  //   path: 'https://sway.office.com/2Vu12qhGnirD3JBX?ref=Link',
  //   icon: icon('ic_home'),
  // },
  {
    title: '＊你的健康分析',
    path: '/health',
    icon: icon('ic_health'),
  },
  {
    title: '活動健康紀錄',
    path: '/health-info',
    icon: icon('ic_health'),
  },
  // {
  //   title: '肢體量度資訊',
  //   path: '/body-info',
  //   icon: icon('ic_health'),
  // },
  // {
  //   title: '聯絡我們',
  //   path: 'contact-us',
  //   icon: icon('ic_home'),
  // },
  // {
  //   title: 'demo',
  //   path: '/demo',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'test',
  //   path: '/test/simple',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
