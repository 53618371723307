import {
  Stack,
  Typography,
  Chip,
  Box,
  Divider,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { getLang, changeLang } from "../../../helper/LangHelper";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function Component({
  title,
  subtitle,
  tags = false,
  description = false,
}) {
  const { i18n } = useTranslation();
  const lang = getLang();

  const [alignment, setAlignment] = useState(lang);
  const handleChange = (event, newAlignment) => {
    i18n.changeLanguage(changeLang(newAlignment));
    setAlignment(newAlignment);
  };

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  const LangBtn = () => {
    const children = [
      <ToggleButton value="en" key="En" sx={{ minWidth: "45px" }}>
        Eng
      </ToggleButton>,
      <ToggleButton value="zh-HK" key="Zh" sx={{ minWidth: "45px" }}>
        中
      </ToggleButton>,
    ];

    return (
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {children}
      </ToggleButtonGroup>
    );
  };
  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="flex-start"
        alignItems={{ xs: "flex-start", sm: "center" }}
        columnGap={2}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={2}
          px={2}
        >
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <LangBtn />
        </Grid>
        {tags ? <Chip label={tags} variant="outlined" /> : null}
      </Stack>
      <Box pb={2}>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <Divider />
      <Box pt={2} px={1}>
        {description}
      </Box>
    </>
  );
}
