

const typographyCommon = {
  dashNum1: {
    fontSize: "3.0rem",
    fontFamily: "HelveticaNeue",
    fontWeight: "bold",
  }
};

export default typographyCommon;
