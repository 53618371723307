import { Route, Navigate } from "react-router-dom";
import Questionnaire from "../../views/Questionnaire";
const routes = {
  // element: <DashboardLayout />,    // TODO: MainLayout

  path: "/questionnaire/:event_id",
  element: <Questionnaire />,
};

export default routes;
