import {
    Typography,
    Card,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../helper/LangHelper";
import ReactApexChart from 'react-apexcharts';
import { useChart } from './chart';
import theme from '../styles/theme';

export default function SimpleRadarChart({ dataList }) {

    console.debug("SimpleRadarChart dataList", dataList);
    // const { t } = useTranslation();

    // dataList = dataList || [];

    // Turn dataList [ {name:'Item1', value:2}, ... ]
    //      to data: [... ] . and chartLabels: [...]
    let chartDataPoints = [];
    let chartLabels = [];
    // let chartColors = [];

    for (let dataItem of dataList) {
        chartDataPoints.push(dataItem.value);
        chartLabels.push(dataItem.name);
        // chartColors.push(dataItem.color);
    }

    // let lang = getLang();
    let chartData =
        [
            { data: chartDataPoints },
            // { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
            // { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
        ];

    const chartColors = [
        theme.palette.primary.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
        theme.palette.panelRed.main,
        theme.palette.info.main,
        theme.palette.warning.main,

    ];

    // const chartLabels = [
    //     'Muscle Endurance',
    //     'Flexibility',
    //     'Agility',
    //     'Power',
    //     'Hand Power',
    //     'Balance',
    // ];
    const chartOptions = useChart({
        stroke: { width: 1 },
        fill: { opacity: 0.48 },
        legend: { floating: true, horizontalAlign: 'center' },
        xaxis: {
            categories: chartLabels,
            labels: {
                style: {
                    colors: chartColors,
                },
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            max: 5,
        },
        plotOptions: {
            radar: {
                polygons: {
                    strokeColor: '#e8e8e8',
                    fill: {
                        colors: ['#f8f8f8', '#fff']
                    }
                }
            }
        }
    });


    return (
        <Card sx={{ p: 0 }}>
            <Stack direction="column">
                {/* <Typography variant="h5">Test Simple</Typography> */}
                <ReactApexChart
                    type="radar"
                    series={chartData}
                    options={chartOptions} height={350} />
            </Stack>
        </Card>
    )
}
