import * as Yup from "yup";

const FormControl = [
  {
    name: "q1",
    question: {
      "zh-HK":
        "1. 醫生曾否說過你的心臟有問題,以及只可進行醫生建議的體能活動? *",
      en: "1. Has your doctor ever said that you have a heart condition and that you should only do physical activity recommended by a doctor? *",
    },
  },
  {
    name: "q2",
    question: {
      "zh-HK": "2. 你進行體能活動時會否感到胸口痛? *",
      en: "2. Do you feel pain in your chest when you do physical activity? *",
    },
  },
  {
    name: "q3",
    question: {
      "zh-HK": "3. 過去一個月內,你曾否在沒有進行體能活動時也感到胸口痛? *",
      en: "3. In the past month, have you had chest pain when you were not doing physical activity? *",
    },
  },
  {
    name: "q4",
    question: {
      "zh-HK": "4. 你曾否因感到暈眩而失去平衡,或曾否失去知覺? *",
      en: "4. Do you lose your balance because of dizziness or do you ever lose consciousness? *",
    },
  },
  {
    name: "q5",
    question: {
      "zh-HK":
        "5. 你的骨骼或關節(例如脊骨、膝蓋或髖關節)是否有毛病,且會因改變體能活動而惡化? *",
      en: "5. Do you have a bone or joint problem (for example, back, knee or hip) that could be made worse by a change in your physical activity? *",
    },
  },
  {
    name: "q6",
    question: {
      "zh-HK": "6. 醫生現時是否有開血壓或心臟藥物(例如 water pills)給你服用? *",
      en: "6. Is your doctor currently prescribing drugs (for example, water pills) for your blood pressure or heart condition? *",
    },
  },
  {
    name: "q7",
    question: {
      "zh-HK": "7. 是否有其他理由令你不應進行體能活動? *",
      en: "7. Do you know of any other reason why you should not do physical activity? *",
    },
  },
];

const Schema = Yup.object().shape({
  q1: Yup.string().required("請選擇一個選項"),
  q2: Yup.string().required("請選擇一個選項"),
  q3: Yup.string().required("請選擇一個選項"),
  q4: Yup.string().required("請選擇一個選項"),
  q5: Yup.string().required("請選擇一個選項"),
  q6: Yup.string().required("請選擇一個選項"),
  q7: Yup.string().required("請選擇一個選項"),
});

const FormikInstance = {
  initialValues: {
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const Content = {
  Schema,
  FormControl,
  FormikInstance,
};

export default Content;
