import { Route, Navigate } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";
// import { getDashboardPageName } from "../helper/GlobalValueHelper";
// import Home from "../views/Home";
import TemiEventInfo from "../views/TemiEventInfo";
import TemiLogin from "../views/TemiLogin";


const route = {
    // element: <DashboardLayout />,    // TODO: MainLayout 
    path: '/temi',
    // element: <HomeLayout />,
    children: [
        {
            path: "login",
            element: <TemiLogin />,
        },
        // Member Event Info
        {
            path: 'event-info/:member_event_id',
            element: <AuthProvider page="temi" action={"view"}>
                <TemiEventInfo />
            </AuthProvider>
        },
    ],

};



export default route;