export function isError(formik, key) {
  return formik.errors[key] && formik.touched[key];
}
export function getErrorMsg(formik, key) {
  return isError(formik, key) ? formik.errors[key] : null;
}
export async function validateForm(formik) {
  await formik.validateForm();

  return !formik.isValid;
}
