// import { useState } from "react";
import { Grid, Box } from "@mui/material";

import { Container } from "@mui/system";
import LoginSection from "../sections/LoginSection";
import LoginTemiForm from "../components/LoginTemiForm";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";
import { TemiApi } from "../api/TemiApi";

const BackGroundSet = () => {
  return (
    <>
      <Box
        position={"absolute"}
        top={40}
        left={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/sphere.png"}
        sx={{
          height: "50vw",
          maxHeight: "300px",
        }}
      />
      <Box
        position={"absolute"}
        top={0}
        left={"50%"}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/cube.png"}
        sx={{
          transform: "translate(-55%, 0)",
          height: "20vw",
          maxHeight: "150px",
        }}
      />
      <Box
        position={"absolute"}
        top={0}
        right={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/pyramid.png"}
        sx={{
          height: "50vw",
          maxHeight: "400px",
        }}
      />
      {/* Bottom */}
      <Box
        position={"absolute"}
        bottom={0}
        right={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/sphere_bottom.png"}
        sx={{
          height: "45vw",
          maxHeight: "280px",
        }}
      />
      <Box
        position={"absolute"}
        bottom={0}
        left={"50%"}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/cube_bottom.png"}
        sx={{
          transform: "translate(-50%, 0)",
          height: "20vw",
          maxHeight: "140px",
        }}
      />
      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        zIndex={-1}
        component={"img"}
        src={"/assets/bgItem/pyramid_bottom.png"}
        sx={{
          height: "30vw",
          maxHeight: "180px",
        }}
      />
    </>
  );
};
export default function Page() {
  const dispatch = useDispatch();


  const onLogin = async (values, mode) => {
    console.debug("onSubmit: ", values);
    const email = values.email;
    const password = values.password;
    const result = await TemiApi.login(email, password);

    // result.jwt = null;
    let jwt = result.jwt;
    let userInfo = result.user;
    const event = result.event;

    console.log("result=", result);

    if (jwt === undefined || jwt == null) {
      // alert("Login failed");

      // ken: simplify the logic 
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "System Error",
      });
      return;
    }

    if (userInfo === undefined || userInfo == null) {
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "System Error",
      });
      return;
    }

    console.log("Event=", event);

    let eventId = event?.id;
    if(eventId === undefined || eventId === null){
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "eventId not found",
      });
      return;
    }

    let memberEventId = result?.member_event_info?.id;
    if(memberEventId === undefined || memberEventId === null){
      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: "Member Event Info not found",
      });
      return;
    }

    dispatch(updateUserInfo(userInfo));
    setLocalStorageUser(userInfo);
    console.debug("jwt: ", jwt);
    console.debug("userInfo: ", userInfo);
    // const eventId = 3474;

    // focus update the react app
    window.location.href = `/temi/event-info/${memberEventId}?mode=${mode}`;
  }



  return (
    <Box position={"relative"} height={`100vh`} overflow="hidden">
      <BackGroundSet />
      <Container>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >

          <Grid item xs={12}>
            <LoginTemiForm onSumbit={onLogin} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
