// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { Container } from "@mui/system";
import ResetPasswordSection from "../sections/ResetPasswordSection";


export default function ResetPassword() {
  
  return (
    <Container sx={{mt:3}}>
      <ResetPasswordSection />
    </Container>
  );
}
