import React, { useEffect, useState } from "react";
import { Divider, Grid, Card, Box, Button, Typography } from "@mui/material";
import palette from "../styles/theme/palette";
import { getLang, localizedName } from "../helper/LangHelper";
import { timeToDateStr } from "../helper/StringHelper";
import EventCard from "./EventCard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function SkillRankTable({
    rankTable, rank, unitName=""
}) {

    // const unitStr = '次';

    const rows = rankTable.map((rankItem) => {
        // Range Str
        const start = rankItem.start;
        const end = rankItem.end;

        let rangeStr;
        
        if(start === undefined){
            rangeStr = `${end}${unitName} 以下`;
        } else if(end === undefined){
            rangeStr = `${start}${unitName} 或以上`;
        } else {
            rangeStr = `${start} ~ ${end} ${unitName}`;
        }

        // Color highlight
        let bgColor = rankItem.rank === rank ? 'primary.lighter' : undefined;

        // 

        return {
            rank: rankItem.rank,
            range: rangeStr,
            bgColor,
        }
    });


    return (
        <Grid>
            <Typography variant="h6" >評分標準</Typography>
            <TableContainer component={Paper}>
                <Table sx={{}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>等級</TableCell>
                            <TableCell align="center">範圍</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (

                            <TableRow
                                key={row.rank}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                         'bgcolor': row.bgColor }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.rank}
                                </TableCell>
                                <TableCell align="center"> {row.range}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </Grid> 
    );
}