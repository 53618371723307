import { LoadingButton } from "@mui/lab";
import { Card, Typography, Grid, TextField, Divider } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import { Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAppName } from "../utils/const";
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import dayjs, { Dayjs } from 'dayjs';

// import Typography from '../styles/overrides/Typography';
import { useFormik } from "formik";

const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));
const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)",
}));

export default function Component({ onSumbit }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedMode, setSelectedMode] = useState("checkin");
  const navigate = useNavigate();

  useEffect(() => {
    if (formData === null) {
      return;
    }
    // console.log(formData);
    if (onSumbit !== undefined) {
      onSumbit(formData, selectedMode);
    }
  }, [formData]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    // validateOnMount: true,
    // validationSchema: LoginSchema,
  });

  const handleCheckIn = () => {
    console.debug("handleCheckIn");
    if (formik.values.email === "" && formik.values.password === "") {
      return;
    }
    setSelectedMode("checkin");
    setFormData(formik.values);
    // setLoading(true);
  };

  const handleCheckOut = () => {
    console.debug("handleCheckOut");
    if (formik.values.email === "" && formik.values.password === "") {
      return;
    }
    setSelectedMode("checkout");
    setFormData(formik.values);
    // setLoading(true);
  };


  const handleBack = () => {
    console.debug("handleBack");
  };


  return (
    <Grid container alignItems="center" px={2}>
      <Grid
        item
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        sm={12}
        xs={12}
        order={{ xs: 1, sm: 2 }}
      >

        <Box display="flex" sx={{ maxWidth: 800, flexDirection: "column", gap: 1 }}>
          <Stack>
            <Typography
              variant="h2"
              align="center"
              sx={{ fontWeight: "bold", pt: 1 }}
            >
              <i>{getAppName()} - Temi Check-in & Check-out</i>
            </Typography>
          </Stack>
          <TextField
            fullWidth
            type="email"
            name="email"
            label="電郵 / 電話"
            variant="outlined"
            // onBlur={handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
          />

          <TextField
            fullWidth
            name="password"
            type="password"
            label="密碼"
            variant="outlined"
            // onBlur={handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}

          />
          {/* <Stack direction="row" alignItems="center" sx={{ my: 0 }}>
            <Typography variant="body2" align="center" sx={{ pr: 2 }}>
              Forgot password?
            </Typography>
            <Button
              variant="text"
              sx={{ fontSize: "body2", my: 0, p: 0 }}
              onClick={() => navigate("/reset-password")}
            >
              Reset Password
            </Button>
          </Stack> */}
        </Box>

        <Stack direction="row" alignItems="center" sx={{ my: 0, width: '100%', gap:2}}>
          <LoadingButton
            type="submit"
            color="primary"
            loading={loading}
            variant="contained"
            onClick={handleCheckIn}
            sx={{ mt: 1 }}
            fullWidth
          >
            Check-In 
          </LoadingButton>

          <LoadingButton
            type="submit"
            // color="tertiary"
            loading={loading}
            variant="contained"
            onClick={handleCheckOut}
            sx={{ mt: 1, backgroundColor: "#FF9900" }}
            fullWidth
          >
            Check-Out
          </LoadingButton>

          <LoadingButton
            type="submit"
            color="secondary"
            loading={loading}
            variant="contained"
            onClick={handleBack}
            sx={{ mt: 1 }}
            fullWidth
          >
            Back
          </LoadingButton>
        </Stack>

        <Box width="100%">
          <Divider sx={{ my: 1 }} />
        </Box>



        {/* </form> */}
      </Grid>
    </Grid>
  );
}
