import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  //   Box,
  //   Typography,
  //   TextField,

  //   Grid,
  //   Card,
  //   CardActions,
  //   CardContent,
  //   FormGroup,
} from "@mui/material";
// import { BodyInfoApi } from "../api/BodyInfoApi";
import { MemberApi } from "../api/MemberApi";
import { useSelector, useDispatch } from "react-redux";
import { memberID, updateAuthState } from "../reducers/userSlice";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import BodyInfoList from "../components/BodyInfoList";

export default function Page() {
  const dispatch = useDispatch();
  const [bodyInfoList, setBodyInfoList] = useState([]);
  // const [eventList, setEventList] = useState([]);

  const currentMemberID = useSelector(memberID);

  const loadDataList = async () => {
    if (currentMemberID === null) {
      return;
    }
    //console.debug("currentMemberID: ", currentMemberID);
    const result = await MemberApi.bodyInfoList(currentMemberID);
    console.debug("result: ", result);
    setBodyInfoList(result);
  };

  useEffect(() => {
    loadDataList();
  }, [currentMemberID]);



  return (
    <Grid>
      <BodyInfoList bodyInfoList={bodyInfoList} />
      {/* Body Info List : {currentMemberID}
      <pre>
        {JSON.stringify(bodyInfoList, null, 2)}
      </pre> */}
      {/* <Box>
        <EventList eventList={eventList} />
      </Box> */}
    </Grid>
  );
}
