import { Routes, Route } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import ThemeRoute from "./ThemeRoute";
import ResetPassword from "../views/ResetPassword";
import PageNotFound from "../views/PageNotFound";
import { Navigate, useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import Login from "../views/Login";
import Register from "../views/Register";
import TestRoutes from "./TestRoutes";
import DemoRoutes from "./DemoRoutes";
import QuestionnaireRoutes from "./QuestionnaireRoutes";
import LogoutPage from "../views/LogoutPage";
import TemiRoutes from "./TemiRoutes";
import BodyInfoList from "../views/BodyInfoList";
// import PageNotFoundRoute from "./PageNotFoundRoute";



// console.debug("env", process.env.NODE_ENV); 
// if (process.env.NODE_ENV === 'production') {
//   // route.children.push({ path: 'accountHelper', element: <TestAccountHelper /> });
//   route = {};
// }

export default function Router() {
    // console.debug("env: ", process.env);
    const routes = useRoutes([
        MainRoutes,
        DemoRoutes,
        TemiRoutes,
        //   {
        //     path: '/',
        //     // element: <DashboardLayout />,
        //     children: [
        //       { element: <Navigate to="/home" />, index: true },
        //       { path: 'home', element: <HomePage /> },
        //       { path: 'skill-list/:event_id', element: <SkillListPage /> },
        //       { path: 'skill-input/:skill_code', element: <SkillInputPage /> },
        //       { path: 'demo', element: <DemoPage /> },
        //     ],
        //   },
        {
            path: "/logout",
            element: <LogoutPage />,
        },
        {
            path: "/login",
            element: <Login />,
        },
       
        {
            path: "/register",
            element: <Register />,
        },
        
        {
            path: "/reset-password",
            element: <ResetPassword />,
        },
        (process.env.NODE_ENV === 'production') ? {} : TestRoutes,
        // TestRoutes,
        {
            path: "/abc",
            children: [
                { path: "a", element: <Login /> },
                { path: "b", element: <Register /> },
                { path: "c", element: <Login /> },
            ],
        },
        {
            // element: <SimpleLayout />,
            path: "/",
            children: [
                // { element: <Navigate to="/dashboard/app" />, index: true },
                { path: "404", element: <PageNotFound /> },
                { path: "*", element: <Navigate to="/404" /> },
            ],
        },
        QuestionnaireRoutes,

        // TestRoutes,
        {
            path: "*",
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}

// export default function Router() {
//     return (
//         <Routes>
//             {/* Login And Register */}
//             {LoginRoute}
//             {/* Home, Member Event */}
//             {HomeRoute}
//             {/* Theme */}
//             {ThemeRoute}
//             {/* Testing  */}
//             {TestRoute}
//             {PageNotFoundRoute}
//         </Routes>
//     );
// }
