import { Navigate } from 'react-router-dom';
import SimplePage from '../views/theme/SimplePage';
import SimpleLayout from '../layout/simple/SimpleLayout';
import HomeLayout from '../layout/home/HomeLayout';


const route = {
  path: '/demo',
  //element: <HomeLayout />,
  element: <SimpleLayout />,
  children: [
    {       
      path: 'simple', element: <SimplePage /> 
    },
    {       
      path: 'simple-home', element: <SimplePage /> 
    },
    // { element: <Navigate to="api" />, index: true },
    // { path: 'simple', element: <TestSimple /> },
    // { path: 'api', element: <TestApi /> },
    // { path: 'logo', element: <TestLogo /> },
    // { path: 'login', element: <TestLogin /> },
    // { path: 'register', element: <TestRegisterForm /> },
    // { path: 'memberInfo', element: <TestMemberEventInfo /> },
    // { path: 'eventList', element: <TestEventList /> },
    // { path: 'mockData', element: <TestMockData /> },
    // { path: 'axiosHelper', element: <TestAxiosHelper /> },
    // { path: 'translate', element: <TestTranslate /> },
    // { path: 'formik', element: <TestFormik /> },
  ]
};

export default route;

// export default (
//   <Route>
    
    
//     <Route path={`${baseRoute}/login`} element={<TestLogin />} />    
//     <Route path={`${baseRoute}/registerForm`} element={<TestRegisterForm />} />    
//     <Route path={`${baseRoute}/memberEventInfo`} element={<TestMemberEventInfo />} />    
//     <Route path={`${baseRoute}/eventList`} element={<TestEventList />} />    
//     <Route path={`${baseRoute}/formik`} element={<TestFormik />} />    
//     <Route path={`${baseRoute}/translate`} element={<TestTranslate />} />    
//     <Route path={`${baseRoute}/mockData`} element={<TestMockData />} />    
//     <Route path={`${baseRoute}/simple`} element={<TestSimple />} />    
//     <Route path={`${baseRoute}/axiosHelper`} element={<TestAxiosHelper />} />    
//     <Route path={`${baseRoute}/api`} element={<TestApi />} />    
    
//   </Route>
// );
