import { ThemeProvider } from "@mui/material/styles";
import ThemeOption from "../styles/theme/themeOption";
import typographyEn from "../styles/theme/typographyEn";
import typographyZh from "../styles/theme/typographyZh";
import { getLang } from "../helper/LangHelper";
import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";


export default function Layout({ children }) {
  // const { i18n } = useTranslation();

  const lang = getLang();
  console.debug("lang=", lang);

  const [Theme, setTheme] = useState(createTheme(ThemeOption));
  useEffect(() => {
    if (lang === "en") {
      ThemeOption.typography = typographyEn;
    } else {
      ThemeOption.typography = typographyZh;
    }
    setTheme(createTheme(ThemeOption));
  }, [lang]);
  return (
    <>
      <ThemeProvider theme={Theme}>{children}</ThemeProvider>
    </>
  );
}
