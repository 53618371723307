import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import {clamp, lerp, ratio} from "../../helper/MathHelper";

export default function TanitaLegMuscleCard({data}) {
    const image = {
        // 704 × 558 pixels
        w: 704, h:558,
        src: "/assets/tanita/Age_Leg_Muscle.png"
    }

    let age = data.age || 0;
    
    // age = Math.c
    let score = data.leg_muscle_score || 0;

    let xRatio = ratio(age, 20, 90);
    let yRatio = ratio(score, 60, 120); // clamp(fat, 0, 3.0) / 50.0;

    // let 
    //           minX maxX 
    let x = lerp( 112,  596, xRatio); // age 
    let y = lerp(  90,  438, yRatio); // fat
    // .         minY  maxY
    
    const markers = [
        { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];
    
    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{maxWidth:900}}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers}/>
        </Stack>
    </Card>
}