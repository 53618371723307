import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#007dc5",
  light: "#004fae",
  main: "#00A9B0",
  dark: "#098a8f",
  darker: "#000d62",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const DARK = {
  lighter: "#333333",
  light: "#333333",
  main: "#333333",
  dark: "#333333",
  darker: "#000",
  contrastText: "#fff",
};

const PANEL_RED =   { main: "#A22", lighter: "#FCC" };
const PANEL_GREEN = { main: "#5A5", lighter: "#CFC" }
const PANEL_BLUE = { main: "#55A", lighter: "#CCF" };

const PANEL_YELLOW = { main: "#AA2", lighter: "#FFC" };
const PANEL_PURPLE = { main: "#A5A", lighter: "#FCF" };
const PANEL_CYAN = { main: "#5AA", lighter: "#CFF" };


const PANEL_AMBER = { main: "#F85", lighter: "#FD2" };
const PANEL_ORANGE = { main: "#FA0", lighter: "#FCA" };//** */
const PANEL_MAGENTA = { main: "#F5F", lighter: "#EDF" };
const PANEL_SKYBLUE = { main: "#0AF", lighter: "#ACF" };




const palette = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  dark: DARK,
  
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: "#fff",
    default: "#EDF2F2",
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  // For Skill Card
  panelRed: PANEL_RED,
  panelGreen: PANEL_GREEN,
  panelYellow: PANEL_YELLOW,
  panelBlue: PANEL_BLUE,
  panelOrange: PANEL_ORANGE,

  panelPurple: PANEL_PURPLE,
  panelAmber: PANEL_AMBER,
  panelMagenta: PANEL_MAGENTA,
  panelSkyBlue: PANEL_SKYBLUE,
  panelCyan: PANEL_CYAN,


//   const PANEL_YELLOW = { main: "#AA2", lighter: "#FFC" };
// const PANEL_BLUE = { main: "#5A5", lighter: "#CFC" };
// const PANEL_ORANGE = { main: "#5A5", lighter: "#CFC" };
// const PANEL_PURPLE = { main: "#5A5", lighter: "#CFC" };

// const PANEL_GREEN = { main: "#5A5", lighter: "#CFC" };
// const PANEL_AMBER = { main: "#5A5", lighter: "#CFC" };
// const PANEL_MAGENTA = { main: "#5A5", lighter: "#CFC" };
// const PANEL_SKYBLUE = { main: "#5A5", lighter: "#CFC" };
// const PANEL_LIME = { main: "#5A5", lighter: "#CFC" };

};

export default palette;
