import * as React from "react";
import Container from "@mui/material/Container";
import { Box, Button, Link } from "@mui/material";

export default function PageNotFound() {
  return (
    <Container maxWidth="false">
      <Box
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "calc(100vh - 128px)",
        }}
      >
        沒有相關網頁
      </Box>
      <Button onClick={() => {
        window.location.href = "/";
      }}>
        返回主頁
      </Button>
    </Container>
  );
}
