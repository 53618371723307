import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Paper, Divider, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Component({ formData }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ReadOnlyParqForm = ({ formData }) => {
    let { question_data } = formData;

    let question = Object.values(question_data.question);
    let answer = Object.values(question_data.answer);

    let valueToDisplay = [
      { label: t("par_q_question.name"), value: () => formData.name },
      { label: t("par_q_question.hkid"), value: () => formData.hkid },
      { label: t("par_q_question.age"), value: () => formData.age },
      {
        label: t("par_q_question.your_identity"),
        value: () =>
          formData.identity === "student_teacher"
            ? t("par_q_question.student_teacher")
            : t("par_q_question.public"),
      },
      {
        label: t("par_q_question.school_name"),
        value: () => formData.schoolName,
      },
      {
        label: t("par_q_question.other_school_name"),
        value: () => formData.otherSchoolName,
      },
      {
        label: t("par_q_question.school_area"),
        value: () => formData.area,
      },
      {
        label: t("par_q_question.edu_grade"),
        value: () =>
          formData.identity === "public" ? null : formData.eduGrade,
      },
      {
        label: t("par_q_question.isPregnant"),
        value: () =>
          formData.isPregnant
            ? t("par_q_question.yes")
            : t("par_q_question.no"),
      },
    ];
    return (
      <Paper elevation={4} sx={{ p: 2 }}>
        {valueToDisplay.map((_v, index) => (
          <Box key={"RO-" + index}>
            <Typography variant="caption">{_v.label}</Typography>
            <br />
            <Typography variant="caption">
              {">"} {_v.value() ? _v.value() : "N/A"}
            </Typography>
          </Box>
        ))}
        <Box my={1}>
          <Divider />
        </Box>
        {question.map((_q, index) => (
          <Box key={`questionRO-${index}`}>
            <Typography variant="caption">{_q}</Typography>
            <br />
            <Typography
              variant="caption"
              color={answer[index] ? "red" : "primary"}
            >
              {answer[index] ? "> 是" : "> 否"}
            </Typography>
          </Box>
        ))}
      </Paper>
    );
  };
  // console.log(formData);
  return formData ? (
    <>
      <Button
        variant="contained"
        color="dark"
        onClick={handleClickOpen}
        sx={{ width: { xs: "100%", sm: "auto" } }}
      >
        查看問卷
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ overflowX: "hidden" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          體能活動適應能力問卷與你 — PAR-Q
        </BootstrapDialogTitle>
        <DialogContent>
          <ReadOnlyParqForm formData={formData} />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  ) : null;
}
