import React from "react";
import { Grid, Card, Box, Stack, Button, Typography } from "@mui/material";
import { localizedName } from "../helper/LangHelper";
import { timeToDateStr } from "../helper/StringHelper";
import EventCard from "./EventCard";
import QRCode from "react-qr-code";


export default function MemberEventInfoCard({ memberEventInfo }) {
  const getQRCode = () => {
    if (memberEventInfo === null) {
      return "";
    }
    var member = memberEventInfo?.member;
    if (member === null) {
      return "";
    }
    return member.qrcode_id;
  }

  return (
    <Card variant="outlined" sx={{ p: 3, mb: 3 }}>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Typography variant="h2">參與號碼</Typography>
        <Grid item>
          <Typography variant="h5" fontWeight={400}>
            {getQRCode()}
          </Typography>
        </Grid>
        <Grid item>
          <Box
            component={QRCode}
            size={100}
            value={getQRCode()}
            fgColor="#000"
            bgColor="#fff"
          />
        </Grid>
      </Grid>
    </Card>
  );
}
