
const getEventRedemptionInfo = () => {
    // redemption_status:
    //      not_ready
    //      ready
    //      redeemed
    return {
        "remain_count": 1,
        "complete_count": 2,
        "total_count": 3,
        "total_score": 1234,
        "reward_chance": 2,
        "updated_at": "2023-10-09 19:18:13",
        "qrcode": "XZFS1231",
        "status": "redeemed",
        // "status" : "ready",
        // "status" : "not_ready",
    }
}

const getEventHealthInfo = () => {
    return [
        {
            "id": 4,
            "member_id": 5762,
            "event_id": 15,
            "age": 30,
            "height": 170.5,
            "weight": 170.5,
            "eye_sight_r": 1,
            "eye_sight_l": 2,
            "eye_sight_color": "P",
            "tanita_info": [
                "http://localhost:9220/media/1691576097_0afcbde10718b4598740.jpeg"
            ],
            "balance_info": [
                "http://localhost:9220/media/1691576097_0afcbde10718b4598740.jpeg"
            ],
            "created_at": "2023-08-08 19:41:43",
            "updated_at": "2023-08-09 18:16:12",
            "deleted_at": "",
            "event_name": "Jocket Event 2023"
        }
    ];
}

const getHealthData = () => {
    return {
        "id": 6,
        "event_id": 1,
        "user_id": "210242223",
        "date": "2022/12/04",
        "time": "17:12",
        "record_time": "2022-12-04 17:12:00",
        "health_data": {
            "model": "MC-980",
            "id_no": "210242223",
            "status": "0",
            "date": "2022/12/04",
            "time": "17:12",
            "body_type": "0",
            "gender": "1",
            "age": 24,
            "height": "168.0",
            "clothes_weight": "0.0",
            "weight": "60.5",
            "fat_p": "15.7",
            "fat_mass": "9.5",
            "ffm": "51.0",
            "muscle_mass": "48.3",
            "muscle_score": "0",
            "bone_mass": "2.7",
            "tbw": "34.2",
            "tbw_p": "56.5",
            "icw": "20.9",
            "ecw": "13.3",
            "ecw_p": "38.9",
            "bmi": "21.4",
            "standard_body_weight": "62.1",
            "degree_of_obesity": "-2.6",
            "standard_fat_p": "17.0",
            "standard_muscle_mass": "48.8",
            "visceral_fat_rating": "4",
            "leg_muscle_score": "111",
            "bmr_kcal": "1467",
            "bmr_kj": "6138",
            "bmr_score": "12",
            "metabolic_age": "18",
            "balancearm": "0",
            "balanceleg": "0",
            "target_fat_p": "17",
            "predicted_weight": "61.4",
            "predicted_fat_mass": "10.4",
            "fat_to_lose__gain": "0.9",
            "rl_fat_p": "17.0",
            "rl_fat_mass": "2.0",
            "rl_ffm": "9.7",
            "rl_muscle_mass": "9.2",
            "rl_fat_p_score": "0",
            "rl_muscle_score": "0",
            "ll_fat_p": "16.8",
            "ll_fat_mass": "1.9",
            "ll_ffm": "9.6",
            "ll_muscle_mass": "9.1",
            "ll_fat_p_score": "-1",
            "ll_muscle_score": "1",
            "ra_fat_p": "11.3",
            "ra_fat_mass": "0.3",
            "ra_ffm": "2.6",
            "ra_muscle_mass": "2.4",
            "ra_fat_p_score": "-2",
            "ra_muscle_score": "2",
            "la_fat_p": "11.1",
            "la_fat_mass": "0.3",
            "la_ffm": "2.6",
            "la_muscle_mass": "2.4",
            "la_fat_p_score": "-2",
            "la_muscle_score": "0",
            "trunk_fat_p": "15.9",
            "trunk_fat_mass": "5.0",
            "trunk_ffm": "26.5",
            "trunk_muscle_mass": "25.2",
            "trunk_fat_p_score": "-1",
            "trunk_muscle_score": "-1",
            "username": "KWAN_WAI_LOK",
            "dummy1": "2000/10/15"
        },
        "created_at": "2022-12-04 12:31:16",
        "updated_at": "2022-12-04 12:31:16",
        "deleted_at": ""
    }
}

const getQuestionnaireData = () => {
    return {
        "member_event_id": 8,
        "member_id": 1,
        "age": 1,
        "is_pregnant": false,
        "question_data": {
            "question": {
                "q1": "Question 1",
                "q2": "Question 2",
                "q3": "Question 3",
                "q4": "Question 4"
            },
            "answer": {
                "q1": "yes",
                "q2": "yes",
                "q3": "yes",
                "q4": "yes"
            }
        },
        "name": "Mosaic",
        "hkid": "K1234",
        "witness": "Digital",
        "self_confirm": false,
        "witness_confirm": false
    };
}

const getBugMemberEventInfo = () => {
    return {
        "id": 2896,
        "access_id": "20231010WYEWBHBS",
        "short_access_id": "BHBS",
        "event_id": 16,
        "member_id": 5774,
        "created_at": "2023-10-10 14:30:40",
        "updated_at": "2023-10-10 14:30:40",
        "deleted_at": "",
        "event": {
            "id": 16,
            "name_en": "Skill Fiesta",
            "name_zh": "Skill Fiesta ZZ",
            "type": "org",
            "organization_id": 65,
            "start_at": "2023-10-05 10:47:57",
            "end_at": "2023-10-27 10:47:56",
            "skill_setting": {
                "adult": [
                    "sitnreach",
                    "balance",
                    "vjump",
                    "stretching",
                    "yingwa_boxing",
                    "kungfu"
                ],
                "child": [
                    "sitnreach",
                    "balance",
                    "vjump",
                    "stretching"
                ]
            },
            "created_at": "2023-10-05 18:48:09",
            "updated_at": "2023-10-06 01:05:36",
            "deleted_at": ""
        },
        "member": {
            "id": 5774,
            "join_time": "2023-08-08 18:09:13",
            "qrcode_id": "NQEGENNK",
            "member_id": "JC23G87",
            "organization_id": 114,
            "user_type": "school",
            "display_name": "CHUEK CHIN YAU",
            "email": "JC23G87",
            "group": "G",
            "group_num": "G87",
            "password_hash": "2fe42f65bdc2ff4d87ccf1e99ea791fbe49eba44",
            "phone_num": "91119222",
            "gender": "M",
            "dob": "2000-01-01",
            "vendor_moveit_id": "",
            "vendor_afascan_id": "",
            "created_at": "2023-08-08 18:09:13",
            "updated_at": "2023-08-08 18:09:13",
            "deleted_at": ""
        },
        "skillInfoList": [
            {
                "id": 8480,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "balance",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-10 01:27:47",
                "updated_at": "2023-10-10 01:27:47",
                "deleted_at": "",
                "skill": {
                    "id": 8,
                    "name_zh": "單腳平衡測試",
                    "name_en": "Single-leg Balance",
                    "code": "balance",
                    "fitness_type": "balance",
                    "fitness_type_en": "Balance",
                    "fitness_type_zh": "平衡性",
                    "unit": "pt",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Balance Device 1",
                                    "topic": "balance-device-1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 61
                        },
                        {
                            "rank": 3,
                            "start": 61,
                            "end": 71
                        },
                        {
                            "rank": 4,
                            "start": 71,
                            "end": 80
                        },
                        {
                            "rank": 5,
                            "start": 80
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 61
                        },
                        {
                            "rank": 3,
                            "start": 61,
                            "end": 71
                        },
                        {
                            "rank": 4,
                            "start": 71,
                            "end": 80
                        },
                        {
                            "rank": 5,
                            "start": 80
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8463,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "vjump",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:13:37",
                "updated_at": "2023-10-09 15:13:37",
                "deleted_at": "",
                "skill": {
                    "id": 2,
                    "name_zh": "垂直跳躍",
                    "name_en": "Vertical Jump",
                    "code": "vjump",
                    "fitness_type": "power",
                    "fitness_type_en": "Power",
                    "fitness_type_zh": "力量",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 3,
                            "start": 51,
                            "end": 61
                        },
                        {
                            "rank": 4,
                            "start": 61,
                            "end": 70
                        },
                        {
                            "rank": 5,
                            "start": 70
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 31
                        },
                        {
                            "rank": 2,
                            "start": 31,
                            "end": 41
                        },
                        {
                            "rank": 3,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 4,
                            "start": 51,
                            "end": 60
                        },
                        {
                            "rank": 5,
                            "start": 60
                        }
                    ],
                    "created_at": "2022-11-28 16:04:11",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8462,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "sitnreach",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:13:30",
                "updated_at": "2023-10-09 15:13:30",
                "deleted_at": "",
                "skill": {
                    "id": 4,
                    "name_zh": "坐地前彎",
                    "name_en": "Sit and Reach",
                    "code": "sitnreach",
                    "fitness_type": "flexibility",
                    "fitness_type_en": "Flexibility",
                    "fitness_type_zh": "柔韧性",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "SitnReach Device 1",
                                    "topic": "microbit/sitnreach/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 16
                        },
                        {
                            "rank": 2,
                            "start": 16,
                            "end": 23
                        },
                        {
                            "rank": 3,
                            "start": 23,
                            "end": 28
                        },
                        {
                            "rank": 4,
                            "start": 28,
                            "end": 34
                        },
                        {
                            "rank": 5,
                            "start": 34
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 23
                        },
                        {
                            "rank": 2,
                            "start": 23,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 33
                        },
                        {
                            "rank": 4,
                            "start": 33,
                            "end": 37
                        },
                        {
                            "rank": 5,
                            "start": 37
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8461,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "stretching",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:13:21",
                "updated_at": "2023-10-09 15:13:21",
                "deleted_at": "",
                "skill": {
                    "id": 27,
                    "name_zh": "拉筋訓練",
                    "name_en": "Stretching",
                    "code": "stretching",
                    "fitness_type": "",
                    "fitness_type_en": "Stretching",
                    "fitness_type_zh": "拉筋訓練",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-10-08 18:23:27",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8460,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "kungfu",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:13:09",
                "updated_at": "2023-10-09 15:13:09",
                "deleted_at": "",
                "skill": {
                    "id": 26,
                    "name_zh": "武藝操",
                    "name_en": "Kung Fu",
                    "code": "kungfu",
                    "fitness_type": "",
                    "fitness_type_en": "Wushu",
                    "fitness_type_zh": "武藝",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-10-08 18:23:27",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8459,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "yingwa_boxing",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:13:02",
                "updated_at": "2023-10-09 15:13:02",
                "deleted_at": "",
                "skill": {
                    "id": 21,
                    "name_zh": "英華拳擊系統",
                    "name_en": "英華拳擊系統",
                    "code": "yingwa_boxing",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingCoach",
                    "fitness_type_zh": "BoxingCoach",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-06-16 14:22:24",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                },
                "gender": "M",
                "rank": 1
            },
            {
                "id": 8458,
                "member_id": 5774,
                "event_id": 16,
                "skill_code": "boxing",
                "skill_score": 0,
                "skill_input_data": {},
                "created_at": "2023-10-09 15:11:50",
                "updated_at": "2023-10-09 15:11:50",
                "deleted_at": "",
                "skill": null,
                "gender": "M",
                "rank": false
            },
            
            {
                "skill_code": "situp",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 1,
                    "name_zh": "1分鐘仰臥起坐",
                    "name_en": "1 min Sit-up",
                    "code": "situp",
                    "fitness_type": "endurance",
                    "fitness_type_en": "Muscle Endurance",
                    "fitness_type_zh": "肌肉耐力",
                    "unit": "time",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Situp Device 1",
                                    "topic": "microbit/situp/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-11-28 07:47:19",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": "2022-11-28 07:47:19"
                }
            },
            {
                "skill_code": "flextest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 5,
                    "name_zh": "Shoulder Flexibility Test",
                    "name_en": "Shoulder Flexibility Test",
                    "code": "flextest",
                    "fitness_type": "shoulder_flex",
                    "fitness_type_en": "Shoulder Flexibility",
                    "fitness_type_zh": "肩部柔韧性",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Shoulder Flex Device 1",
                                    "topic": "microbit/flextest/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 20,
                            "end": 1000
                        },
                        {
                            "rank": 2,
                            "start": 15,
                            "end": 19.99
                        },
                        {
                            "rank": 3,
                            "start": 10,
                            "end": 14.99
                        },
                        {
                            "rank": 4,
                            "start": 5,
                            "end": 9.99
                        },
                        {
                            "rank": 5,
                            "start": -1000,
                            "end": 5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 20,
                            "end": 1000
                        },
                        {
                            "rank": 2,
                            "start": 15,
                            "end": 19.99
                        },
                        {
                            "rank": 3,
                            "start": 10,
                            "end": 14.99
                        },
                        {
                            "rank": 4,
                            "start": 5,
                            "end": 9.99
                        },
                        {
                            "rank": 5,
                            "start": -1000,
                            "end": 5
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "ttest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 6,
                    "name_zh": "T-敏捷跑",
                    "name_en": "T-Test",
                    "code": "ttest",
                    "fitness_type": "agility",
                    "fitness_type_en": "Agility",
                    "fitness_type_zh": "敏捷性",
                    "unit": "sec",
                    "decimal_place": 0,
                    "rank_direction": "asc",
                    "import_setting": {
                        "file_import": {
                            "parser": "TTestParser"
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 12.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 11.5,
                            "end": 12.5
                        },
                        {
                            "rank": 3,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 4,
                            "start": 9.5,
                            "end": 10.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 9.5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 13.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 12.5,
                            "end": 13.5
                        },
                        {
                            "rank": 3,
                            "start": 11.5,
                            "end": 12.49
                        },
                        {
                            "rank": 4,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 10.5
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "handgrip",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 7,
                    "name_zh": "手握力",
                    "name_en": "Hand Grip",
                    "code": "handgrip",
                    "fitness_type": "grip_power",
                    "fitness_type_en": "Grip Power",
                    "fitness_type_zh": "握力",
                    "unit": "kg",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "file_import": {
                            "parser": "HandGripParser"
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 40
                        },
                        {
                            "rank": 2,
                            "start": 40,
                            "end": 48
                        },
                        {
                            "rank": 3,
                            "start": 48,
                            "end": 56
                        },
                        {
                            "rank": 4,
                            "start": 56,
                            "end": 64
                        },
                        {
                            "rank": 5,
                            "start": 64
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 31
                        },
                        {
                            "rank": 2,
                            "start": 31,
                            "end": 41
                        },
                        {
                            "rank": 3,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 4,
                            "start": 51,
                            "end": 60
                        },
                        {
                            "rank": 5,
                            "start": 60
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "bike",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 9,
                    "name_zh": "智能單車20秒衝刺",
                    "name_en": "Smart Cycling 20 secs Sprint",
                    "code": "bike",
                    "fitness_type": "",
                    "fitness_type_en": "Smart Cycling",
                    "fitness_type_zh": "Smart Cycling",
                    "unit": "kph",
                    "decimal_place": 1,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "punch",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 10,
                    "name_zh": "Power Punching",
                    "name_en": "Power Punching",
                    "code": "punch",
                    "fitness_type": "",
                    "fitness_type_en": "Power",
                    "fitness_type_zh": "Power",
                    "unit": "n",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "skipping",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 11,
                    "name_zh": "1 min - Smart Rope Skipping",
                    "name_en": "1 min - Smart Rope Skipping",
                    "code": "skipping",
                    "fitness_type": "",
                    "fitness_type_en": "Coordination",
                    "fitness_type_zh": "Coordination",
                    "unit": "time",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "football",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 12,
                    "name_zh": "Football Challenge",
                    "name_en": "Football Challenge",
                    "code": "football",
                    "fitness_type": "",
                    "fitness_type_en": "Football",
                    "fitness_type_zh": "Football",
                    "unit": "sec",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "hand_rolling",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 13,
                    "name_zh": "30s Hand Rolling",
                    "name_en": "30s Hand Rolling",
                    "code": "hand_rolling",
                    "fitness_type": "",
                    "fitness_type_en": "Hand Rolling",
                    "fitness_type_zh": "Hand Rolling",
                    "unit": "score",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "pingpong",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 14,
                    "name_zh": "PingPong Challenge",
                    "name_en": "PingPong Challenge",
                    "code": "pingpong",
                    "fitness_type": "",
                    "fitness_type_en": "PingPong",
                    "fitness_type_zh": "PingPong",
                    "unit": "sec",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_coach",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 15,
                    "name_zh": "拳擊跟練",
                    "name_en": "MoveIt Boxing Coach",
                    "code": "moveit_coach",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingCoach",
                    "fitness_type_zh": "BoxingCoach",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_ai_punch",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 16,
                    "name_zh": "AI 拳擊",
                    "name_en": "MoveIt AI Boxing",
                    "code": "moveit_ai_punch",
                    "fitness_type": "",
                    "fitness_type_en": "AIBoxing",
                    "fitness_type_zh": "AIBoxing",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_quiz",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 17,
                    "name_zh": "拳擊答題挑戰",
                    "name_en": "MoveIt Boxing Quiz",
                    "code": "moveit_quiz",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingQuiz",
                    "fitness_type_zh": "BoxingQuiz",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "ptest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 20,
                    "name_zh": "Patagonia Agility",
                    "name_en": "Patagonia Agility",
                    "code": "ptest",
                    "fitness_type": "agility",
                    "fitness_type_en": "Agility",
                    "fitness_type_zh": "敏捷性",
                    "unit": "sec",
                    "decimal_place": 0,
                    "rank_direction": "asc",
                    "import_setting": {},
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 12.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 11.5,
                            "end": 12.5
                        },
                        {
                            "rank": 3,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 4,
                            "start": 9.5,
                            "end": 10.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 9.5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 13.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 12.5,
                            "end": 13.5
                        },
                        {
                            "rank": 3,
                            "start": 11.5,
                            "end": 12.49
                        },
                        {
                            "rank": 4,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 10.5
                        }
                    ],
                    "created_at": "2023-06-16 14:22:24",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "smart_dumbbell",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 22,
                    "name_zh": "智能啞鈴遊戲",
                    "name_en": "Smart Dumbbell",
                    "code": "smart_dumbbell",
                    "fitness_type": "",
                    "fitness_type_en": "Smart Dumb Bell",
                    "fitness_type_zh": "Smart Dumb Bell",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-06-28 19:33:42",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "curl_up",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 23,
                    "name_zh": "1 min curl-up",
                    "name_en": "1 min curl-up",
                    "code": "curl_up",
                    "fitness_type": "",
                    "fitness_type_en": "1 min curl-up",
                    "fitness_type_zh": "1 min curl-up",
                    "unit": "time",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "reaction",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 24,
                    "name_zh": "Reaction Test",
                    "name_en": "Reaction Test",
                    "code": "reaction",
                    "fitness_type": "",
                    "fitness_type_en": "Reaction Test",
                    "fitness_type_zh": "Reaction Test",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "yoyo",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 25,
                    "name_zh": "Yo Yo Run",
                    "name_en": "Yo Yo Run",
                    "code": "yoyo",
                    "fitness_type": "",
                    "fitness_type_en": "Yo Yo Run",
                    "fitness_type_zh": "Yo Yo Run",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:32:25",
                    "deleted_at": ""
                }
            }
        ],
        "questionnaire": null,
        "questionnaire_status": "not_start"
    }
}

const getMemberEventInfo = () => {
    return {
        "id": 2894,
        "access_id": "20231008QGCTBHBQ",
        "short_access_id": "BHBQ",
        "event_id": 16,
        "member_id": 2934,
        "created_at": "2023-10-08 14:56:23",
        "updated_at": "2023-10-08 14:56:23",
        "deleted_at": "",
        "event": {
            "id": 16,
            "name_en": "Skill Fiesta",
            "name_zh": "Skill Fiesta ZZ",
            "type": "org",
            "organization_id": 65,
            "start_at": "2023-10-05 10:47:57",
            "end_at": "2023-10-27 10:47:56",
            "skill_setting": {
                "adult": [
                    "sitnreach",
                    "balance",
                    "vjump",
                    "stretching",
                    "yingwa_boxing",
                    "kungfu"
                ],
                "child": [
                    "sitnreach",
                    "balance",
                    "vjump",
                    "stretching"
                ]
            },
            "created_at": "2023-10-05 18:48:09",
            "updated_at": "2023-10-06 01:05:36",
            "deleted_at": ""
        },
        "member": {
            "id": 2934,
            "join_time": "2023-02-22 10:20:08",
            "qrcode_id": "VTC23AXQF",
            "member_id": "vtc",
            "organization_id": 2,
            "user_type": "internal",
            "display_name": "VTC",
            "email": "vtc",
            "group": "",
            "group_num": "",
            "password_hash": "4a240ec058b403b420ec3ba1b1c3acc6cadf3ac5",
            "phone_num": "91239123",
            "gender": "M",
            "dob": "2000-01-01",
            "vendor_moveit_id": "",
            "vendor_afascan_id": "",
            "created_at": "2023-02-22 10:20:08",
            "updated_at": "2023-02-22 10:20:08",
            "deleted_at": ""
        },
        "skillInfoList": [
            {
                "skill_code": "situp",
                "skill_score": 20,
                "gender": "M",
                "rank": 3,
                "participated": false,
                "skill": {
                    "id": 1,
                    "name_zh": "1分鐘仰臥起坐",
                    "name_en": "1 min Sit-up",
                    "code": "situp",
                    "fitness_type": "endurance",
                    "fitness_type_en": "Muscle Endurance",
                    "fitness_type_zh": "肌肉耐力",
                    "unit": "time",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Situp Device 1",
                                    "topic": "microbit/situp/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-11-28 07:47:19",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": "2022-11-28 07:47:19"
                }
            },
            {
                "skill_code": "vjump",
                "skill_score": 30,
                "gender": "M",
                "rank": 2,
                "participated": false,
                "skill": {
                    "id": 2,
                    "name_zh": "垂直跳躍",
                    "name_en": "Vertical Jump",
                    "code": "vjump",
                    "fitness_type": "power",
                    "fitness_type_en": "Power",
                    "fitness_type_zh": "力量",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 3,
                            "start": 51,
                            "end": 61
                        },
                        {
                            "rank": 4,
                            "start": 61,
                            "end": 70
                        },
                        {
                            "rank": 5,
                            "start": 70
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 31
                        },
                        {
                            "rank": 2,
                            "start": 31,
                            "end": 41
                        },
                        {
                            "rank": 3,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 4,
                            "start": 51,
                            "end": 60
                        },
                        {
                            "rank": 5,
                            "start": 60
                        }
                    ],
                    "created_at": "2022-11-28 16:04:11",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "sitnreach",
                "skill_score": 20,
                "gender": "M",
                "rank": 1,
                "participated": false,
                "skill": {
                    "id": 4,
                    "name_zh": "坐地前彎",
                    "name_en": "Sit and Reach",
                    "code": "sitnreach",
                    "fitness_type": "flexibility",
                    "fitness_type_en": "Flexibility",
                    "fitness_type_zh": "柔韧性",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "SitnReach Device 1",
                                    "topic": "microbit/sitnreach/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 16
                        },
                        {
                            "rank": 2,
                            "start": 16,
                            "end": 23
                        },
                        {
                            "rank": 3,
                            "start": 23,
                            "end": 28
                        },
                        {
                            "rank": 4,
                            "start": 28,
                            "end": 34
                        },
                        {
                            "rank": 5,
                            "start": 34
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 23
                        },
                        {
                            "rank": 2,
                            "start": 23,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 33
                        },
                        {
                            "rank": 4,
                            "start": 33,
                            "end": 37
                        },
                        {
                            "rank": 5,
                            "start": 37
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "flextest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 5,
                    "name_zh": "Shoulder Flexibility Test",
                    "name_en": "Shoulder Flexibility Test",
                    "code": "flextest",
                    "fitness_type": "shoulder_flex",
                    "fitness_type_en": "Shoulder Flexibility",
                    "fitness_type_zh": "肩部柔韧性",
                    "unit": "cm",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Shoulder Flex Device 1",
                                    "topic": "microbit/flextest/1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 20,
                            "end": 1000
                        },
                        {
                            "rank": 2,
                            "start": 15,
                            "end": 19.99
                        },
                        {
                            "rank": 3,
                            "start": 10,
                            "end": 14.99
                        },
                        {
                            "rank": 4,
                            "start": 5,
                            "end": 9.99
                        },
                        {
                            "rank": 5,
                            "start": -1000,
                            "end": 5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 20,
                            "end": 1000
                        },
                        {
                            "rank": 2,
                            "start": 15,
                            "end": 19.99
                        },
                        {
                            "rank": 3,
                            "start": 10,
                            "end": 14.99
                        },
                        {
                            "rank": 4,
                            "start": 5,
                            "end": 9.99
                        },
                        {
                            "rank": 5,
                            "start": -1000,
                            "end": 5
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "ttest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 6,
                    "name_zh": "T-敏捷跑",
                    "name_en": "T-Test",
                    "code": "ttest",
                    "fitness_type": "agility",
                    "fitness_type_en": "Agility",
                    "fitness_type_zh": "敏捷性",
                    "unit": "sec",
                    "decimal_place": 0,
                    "rank_direction": "asc",
                    "import_setting": {
                        "file_import": {
                            "parser": "TTestParser"
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 12.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 11.5,
                            "end": 12.5
                        },
                        {
                            "rank": 3,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 4,
                            "start": 9.5,
                            "end": 10.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 9.5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 13.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 12.5,
                            "end": 13.5
                        },
                        {
                            "rank": 3,
                            "start": 11.5,
                            "end": 12.49
                        },
                        {
                            "rank": 4,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 10.5
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "handgrip",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 7,
                    "name_zh": "手握力",
                    "name_en": "Hand Grip",
                    "code": "handgrip",
                    "fitness_type": "grip_power",
                    "fitness_type_en": "Grip Power",
                    "fitness_type_zh": "握力",
                    "unit": "kg",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "file_import": {
                            "parser": "HandGripParser"
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 40
                        },
                        {
                            "rank": 2,
                            "start": 40,
                            "end": 48
                        },
                        {
                            "rank": 3,
                            "start": 48,
                            "end": 56
                        },
                        {
                            "rank": 4,
                            "start": 56,
                            "end": 64
                        },
                        {
                            "rank": 5,
                            "start": 64
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 31
                        },
                        {
                            "rank": 2,
                            "start": 31,
                            "end": 41
                        },
                        {
                            "rank": 3,
                            "start": 41,
                            "end": 51
                        },
                        {
                            "rank": 4,
                            "start": 51,
                            "end": 60
                        },
                        {
                            "rank": 5,
                            "start": 60
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "balance",
                "skill_score": 30,
                "gender": "M",
                "rank": 3,
                "participated": false,
                "skill": {
                    "id": 8,
                    "name_zh": "單腳平衡測試",
                    "name_en": "Single-leg Balance",
                    "code": "balance",
                    "fitness_type": "balance",
                    "fitness_type_en": "Balance",
                    "fitness_type_zh": "平衡性",
                    "unit": "pt",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": {
                        "auto_import": {
                            "type": "mqtt",
                            "devices": [
                                {
                                    "name": "Balance Device 1",
                                    "topic": "balance-device-1"
                                }
                            ]
                        }
                    },
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 61
                        },
                        {
                            "rank": 3,
                            "start": 61,
                            "end": 71
                        },
                        {
                            "rank": 4,
                            "start": 71,
                            "end": 80
                        },
                        {
                            "rank": 5,
                            "start": 80
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "end": 41
                        },
                        {
                            "rank": 2,
                            "start": 41,
                            "end": 61
                        },
                        {
                            "rank": 3,
                            "start": 61,
                            "end": 71
                        },
                        {
                            "rank": 4,
                            "start": 71,
                            "end": 80
                        },
                        {
                            "rank": 5,
                            "start": 80
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "bike",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 9,
                    "name_zh": "智能單車20秒衝刺",
                    "name_en": "Smart Cycling 20 secs Sprint",
                    "code": "bike",
                    "fitness_type": "",
                    "fitness_type_en": "Smart Cycling",
                    "fitness_type_zh": "Smart Cycling",
                    "unit": "kph",
                    "decimal_place": 1,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "punch",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 10,
                    "name_zh": "Power Punching",
                    "name_en": "Power Punching",
                    "code": "punch",
                    "fitness_type": "",
                    "fitness_type_en": "Power",
                    "fitness_type_zh": "Power",
                    "unit": "n",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "skipping",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 11,
                    "name_zh": "1 min - Smart Rope Skipping",
                    "name_en": "1 min - Smart Rope Skipping",
                    "code": "skipping",
                    "fitness_type": "",
                    "fitness_type_en": "Coordination",
                    "fitness_type_zh": "Coordination",
                    "unit": "time",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-12-02 18:12:46",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "football",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 12,
                    "name_zh": "Football Challenge",
                    "name_en": "Football Challenge",
                    "code": "football",
                    "fitness_type": "",
                    "fitness_type_en": "Football",
                    "fitness_type_zh": "Football",
                    "unit": "sec",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "hand_rolling",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 13,
                    "name_zh": "30s Hand Rolling",
                    "name_en": "30s Hand Rolling",
                    "code": "hand_rolling",
                    "fitness_type": "",
                    "fitness_type_en": "Hand Rolling",
                    "fitness_type_zh": "Hand Rolling",
                    "unit": "score",
                    "decimal_place": 0,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "pingpong",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 14,
                    "name_zh": "PingPong Challenge",
                    "name_en": "PingPong Challenge",
                    "code": "pingpong",
                    "fitness_type": "",
                    "fitness_type_en": "PingPong",
                    "fitness_type_zh": "PingPong",
                    "unit": "sec",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2022-12-21 02:09:43",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_coach",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 15,
                    "name_zh": "拳擊跟練",
                    "name_en": "MoveIt Boxing Coach",
                    "code": "moveit_coach",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingCoach",
                    "fitness_type_zh": "BoxingCoach",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_ai_punch",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 16,
                    "name_zh": "AI 拳擊",
                    "name_en": "MoveIt AI Boxing",
                    "code": "moveit_ai_punch",
                    "fitness_type": "",
                    "fitness_type_en": "AIBoxing",
                    "fitness_type_zh": "AIBoxing",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "moveit_quiz",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 17,
                    "name_zh": "拳擊答題挑戰",
                    "name_en": "MoveIt Boxing Quiz",
                    "code": "moveit_quiz",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingQuiz",
                    "fitness_type_zh": "BoxingQuiz",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-04-24 18:02:05",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "ptest",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 20,
                    "name_zh": "Patagonia Agility",
                    "name_en": "Patagonia Agility",
                    "code": "ptest",
                    "fitness_type": "agility",
                    "fitness_type_en": "Agility",
                    "fitness_type_zh": "敏捷性",
                    "unit": "sec",
                    "decimal_place": 0,
                    "rank_direction": "asc",
                    "import_setting": {},
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 12.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 11.5,
                            "end": 12.5
                        },
                        {
                            "rank": 3,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 4,
                            "start": 9.5,
                            "end": 10.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 9.5
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 13.5,
                            "end": 10000
                        },
                        {
                            "rank": 2,
                            "start": 12.5,
                            "end": 13.5
                        },
                        {
                            "rank": 3,
                            "start": 11.5,
                            "end": 12.49
                        },
                        {
                            "rank": 4,
                            "start": 10.5,
                            "end": 11.49
                        },
                        {
                            "rank": 5,
                            "start": -10000,
                            "end": 10.5
                        }
                    ],
                    "created_at": "2023-06-16 14:22:24",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "yingwa_boxing",
                "skill_score": 60,
                "gender": "M",
                "rank": 2,
                "participated": false,
                "skill": {
                    "id": 21,
                    "name_zh": "英華拳擊系統",
                    "name_en": "英華拳擊系統",
                    "code": "yingwa_boxing",
                    "fitness_type": "",
                    "fitness_type_en": "BoxingCoach",
                    "fitness_type_zh": "BoxingCoach",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-06-16 14:22:24",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "smart_dumbbell",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 22,
                    "name_zh": "智能啞鈴遊戲",
                    "name_en": "Smart Dumbbell",
                    "code": "smart_dumbbell",
                    "fitness_type": "",
                    "fitness_type_en": "Smart Dumb Bell",
                    "fitness_type_zh": "Smart Dumb Bell",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-06-28 19:33:42",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "curl_up",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 23,
                    "name_zh": "1 min curl-up",
                    "name_en": "1 min curl-up",
                    "code": "curl_up",
                    "fitness_type": "",
                    "fitness_type_en": "1 min curl-up",
                    "fitness_type_zh": "1 min curl-up",
                    "unit": "time",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "reaction",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 24,
                    "name_zh": "Reaction Test",
                    "name_en": "Reaction Test",
                    "code": "reaction",
                    "fitness_type": "",
                    "fitness_type_en": "Reaction Test",
                    "fitness_type_zh": "Reaction Test",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "yoyo",
                "skill_score": 0,
                "gender": "M",
                "rank": 0,
                "participated": false,
                "skill": {
                    "id": 25,
                    "name_zh": "Yo Yo Run",
                    "name_en": "Yo Yo Run",
                    "code": "yoyo",
                    "fitness_type": "",
                    "fitness_type_en": "Yo Yo Run",
                    "fitness_type_zh": "Yo Yo Run",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-08-08 17:14:32",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "kungfu",
                "skill_score": 50,
                "gender": "M",
                "rank": 3,
                "participated": false,
                "skill": {
                    "id": 26,
                    "name_zh": "武藝操",
                    "name_en": "Kung Fu",
                    "code": "kungfu",
                    "fitness_type": "",
                    "fitness_type_en": "Wushu",
                    "fitness_type_zh": "武藝",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-10-08 18:23:27",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            },
            {
                "skill_code": "stretching",
                "skill_score": 30,
                "gender": "M",
                "rank": 4,
                "participated": false,
                "skill": {
                    "id": 27,
                    "name_zh": "拉筋訓練",
                    "name_en": "Stretching",
                    "code": "stretching",
                    "fitness_type": "",
                    "fitness_type_en": "Stretching",
                    "fitness_type_zh": "拉筋訓練",
                    "unit": "pt",
                    "decimal_place": 2,
                    "rank_direction": "desc",
                    "import_setting": null,
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 25
                        },
                        {
                            "rank": 2,
                            "start": 25,
                            "end": 35
                        },
                        {
                            "rank": 3,
                            "start": 35,
                            "end": 44
                        },
                        {
                            "rank": 4,
                            "start": 44,
                            "end": 49
                        },
                        {
                            "rank": 5,
                            "start": 49
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 18
                        },
                        {
                            "rank": 2,
                            "start": 18,
                            "end": 29
                        },
                        {
                            "rank": 3,
                            "start": 29,
                            "end": 37
                        },
                        {
                            "rank": 4,
                            "start": 37,
                            "end": 43
                        },
                        {
                            "rank": 5,
                            "start": 43
                        }
                    ],
                    "created_at": "2023-10-08 18:23:27",
                    "updated_at": "2023-10-08 18:23:27",
                    "deleted_at": ""
                }
            }
        ],
        "questionnaire": null,
        "questionnaire_status": "not_start"
    };
}

const getEventList = () => {
    return [
        {
            "id": 1,
            "name_en": "VTC Demo Day",
            "name_zh": "VTC 資訊日",
            "type": "public",
            "start_at": "2021-11-23 00:14:33",
            "end_at": "2021-12-20 23:59:00",
            "created_at": "2022-11-26 12:19:51",
            "updated_at": "2022-11-26 12:19:51",
            "deleted_at": "",
            "member_info": {
                "id": 4,
                "access_id": "202211270000000004",
                "short_access_id": "NAXZ",
                "event_id": 1,
                "member_id": 1,
                "created_at": "2022-11-27 20:42:11",
                "updated_at": "2022-11-27 20:42:11",
                "deleted_at": ""
            }
        },
        {
            "id": 2,
            "name_en": "VTC Demo Day 2020",
            "name_zh": "VTC 資訊日 2020",
            "type": "public",
            "start_at": "2020-11-22 00:14:33",
            "end_at": "2020-12-20 23:59:00",
            "created_at": "2022-11-26 12:26:18",
            "updated_at": "2022-11-26 12:26:18",
            "deleted_at": "",
            "member_info": null
        }
    ]
}

const getBodyInfoList = () => {
    return [
        {
            "id": 1,
            "member_id": 12,
            "body_shape_image": "http://localhost:9220/media/1682507472_8fbb2e2ef2c0f8eefd04.png",
            "body_shape_data": {
                "height": 123,
                "weight": 123,
                "shoulder": 33
            },
            "vendor": "xxxx",
            "created_at": "2023-04-26 19:11:12",
            "updated_at": "2023-04-26 19:11:12",
            "deleted_at": ""
        }
    ]
}

const MockData = {
    getBugMemberEventInfo,
    getEventRedemptionInfo,
    getEventHealthInfo,
    getBodyInfoList,
    getEventList,
    getMemberEventInfo,
    getHealthData,
    getQuestionnaireData,
};
export default MockData;