import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: false,
  jwt: null,
  id: null,
  name: "",
  email: "",
  gender: "",
  user_type: "",
  dob: "",
  role: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, value) => {
      state.auth = true;
      state.jwt = value.payload.jwt;
      state.id = value.payload.id;
      state.name = value.payload.display_name;
      state.gender = value.payload.gender;
      state.email = value.payload.email;
      state.user_type = value.payload.user_type;
      state.dob = value.payload.dob;
      // state.role = value.payload.role;
    },
    updateAuthState: (state, value) => {
      state.auth = value.payload;
    },
    updateJwtState: (state, value) => {
      state.jwt = value.payload;
    },
    updateUserIdState: (state, value) => {
      state.id = value.payload;
    },
    updateUserNameState: (state, value) => {
      state.name = value.payload;
    },
  },
});

export const {
  updateUserInfo,
  updateAuthState,
  updateJwtState,
  updateUserIdState,
  updateUserNameState,
} = userSlice.actions;

export const auth = (state) => state.user.auth;
export const jwtToken = (state) => state.user.jwt;
export const memberID = (state) => state.user.id;
export const memberName = (state) => state.user.name;
export const dateOfBirth = (state) => state.user.dob;
export const email = (state) => state.user.email;
export const memberType = (state) => state.user.user_type;
export const gender = (state) => state.user.gender;

export default userSlice.reducer;
