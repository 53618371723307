import { Grid, Box, Typography, Paper, Divider } from "@mui/material";
import FormContent from "../ParqQuestion/parqFormSchema.jsx";
import { useTranslation } from "react-i18next";
export default function Component({ formik, stepperProgress = [] }) {
  const { i18n, t } = useTranslation();
  const ReadOnlyParqForm = () => {
    let valueToDisplay = [
      { label: t("par_q_question.name"), value: () => formik.values.name },
      { label: t("par_q_question.hkid"), value: () => formik.values.hkid },
      { label: t("par_q_question.age"), value: () => formik.values.age },
      {
        label: t("par_q_question.your_identity"),
        value: () =>
          formik.values.identity === "student_teacher"
            ? t("par_q_question.student_teacher")
            : t("par_q_question.public"),
      },
      {
        label: t("par_q_question.school_name"),
        value: () => formik.values.schoolName,
      },
      {
        label: t("par_q_question.other_school_name"),
        value: () => formik.values.otherSchoolName,
      },
      {
        label: t("par_q_question.school_area"),
        value: () => formik.values.area,
      },
      {
        label: t("par_q_question.edu_grade"),
        value: () =>
          formik.values.identity === "public" ? null : formik.values.eduGrade,
      },
      {
        label: t("par_q_question.isPregnant"),
        value: () =>
          formik.values.isPregnant
            ? t("par_q_question.yes")
            : t("par_q_question.no"),
      },
    ];
    return (
      <Paper elevation={4} sx={{ p: 2, my: 3 }}>
        {valueToDisplay.map((_v, index) => (
          <Box key={"RO-" + index}>
            <Typography variant="caption">{_v.label}</Typography>
            <br />
            <Typography variant="caption">
              {">"} {_v.value() ? _v.value() : "N/A"}
            </Typography>
          </Box>
        ))}
        <Box my={1}>
          <Divider />
        </Box>
        {FormContent.FormControl.map((item, index) => (
          <Box key={`questionRO-${index}`}>
            <Typography variant="caption">
              {item.question[i18n.language]}
            </Typography>
            <br />
            <Typography variant="caption">
              {formik.values[item.name]
                ? `> ${t("par_q_question.yes")}`
                : `> ${t("par_q_question.no")}`}
            </Typography>
          </Box>
        ))}
      </Paper>
    );
  };
  const ThankyouContent = {
    pass: {
      label: t("par_q_question.thankyou"),
      subText: t("par_q_question.confirm_msg_pass"),
      caption: t("par_q_question.confirm_caption"),
    },
    nonPass: {
      label: t("par_q_question.sorry"),
      subText: t("par_q_question.confirm_msg_not_pass"),
      caption: t("par_q_question.confirm_caption"),
    },
  };

  const _c = stepperProgress.includes(2)
    ? ThankyouContent.pass
    : ThankyouContent.nonPass;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h5"} mb={2}>
            {_c.label}
          </Typography>
          <Typography>{_c.subText}</Typography>
          {/* Display Readonly form if the question had been answered */}
          {stepperProgress.includes(1) ? <ReadOnlyParqForm /> : null}
          <Typography>{_c.caption}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
