import {
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../helper/LangHelper";
import ReactApexChart from 'react-apexcharts';
import { useChart } from './chart';
import theme from '../styles/theme';
import SimpleRadarChart from './SimpleRadarChart';


function getDefaultSkills() {
    return [
        "vjump",
        "sitnreach",
        "situp",
        "balance",
    ]
}

function getCareerDataList() {
    return [
        {
            name: "內容製作",
            value: 5,
            color: '#ff0',
        },
        {
            name: "IT技能",
            value: 4,
            color: '#ff0',
        },
        {
            name: "人際交往",
            value: 3,
            color: '#ff0',
        },
        {
            name: "時間管理",
            value: 2,
            color: '#ff0',
        },

        {
            name: "推銷",
            value: 2,
            color: '#ff0',
        },
        {
            name: "數據分析",
            value: 2,
            color: '#ff0',
        },
    ]

}

export default function CareerRadarChart({ skillList, radarItems }) {
    const { t } = useTranslation();

    radarItems = radarItems || getDefaultSkills();

    console.debug("SkillRadarChart: skillList: ", skillList);
    console.debug("SkillRadarChart: radarItems: ", radarItems);

    // let radarItems = ['vjump', 'situp', 'sitnreach', 'flextest',
    //                 'ttest', 'handgrip', 'balance'];

    // let radarItems = ['vjump', 'curl_up', 'sitnreach', 'reaction',
    //     'yoyo', 'handgrip', 'balance'];
    let filteredList = skillList.filter((item) => {
        if(item.skill === null || item.skill === undefined){
            return false;
        }
        return radarItems.includes(item.skill.code);
    });

    let dataList = getCareerDataList();



    let lang = getLang();
    let chartData =
        [
            { name: 'Series 1', data: [1, 4, 5, 2, 3, 0] },
            // { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
            // { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
        ];

    const chartColors = [
        theme.palette.primary.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
        theme.palette.panelRed.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
        theme.palette.primary.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
    ];


    return (

        <SimpleRadarChart dataList={dataList} />        
    )
}
