import { LoadingButton } from '@mui/lab';
import { Card, Typography, Grid, TextField, Divider } from '@mui/material';
import { Box, Stack, styled } from '@mui/system';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import dayjs, { Dayjs } from 'dayjs';

import ReactSwal from "../helper/AlertHelper";

// import Typography from '../styles/overrides/Typography';
import { useFormik } from "formik";


export default function ResetPasswordForm({ onSubmit, loading }) {
    //const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (formData === null) {
            return;
        }
        console.log(formData);
        if (onSubmit !== undefined) {
            onSubmit(formData);
        }
    }, [formData]);

    const formik = useFormik({
        initialValues: {
            account: "",
        },
    });

    const handleSubmit = () => {
        console.debug("handleOnSubmit");
        let trimAccount = formik.values.account.trim();
        if (trimAccount === '') {
            ReactSwal.fire({
                // title: '請輸入電郵或聯絡電話',
                html: '請輸入電郵或聯絡電話',
                //html: response.data.message || "Unknown error",
            });
            // AlertHelper.showAlert('請輸入電郵或聯絡電話');
            return;
        }

        setFormData(formik.values);
        // setLoading(true);
    }

    return (

        <Card className="card" sx={{ width: '100%', maxWidth: 600 }} >
            <Box p={4} component={Stack} gap={2} height="100%">
                <Stack>
                    <Box component="img"
                        width="60px"
                        alt="Login"
                        src="/logo.gif"
                        alignSelf="center"
                    />
                    <Typography variant="h5" align="center" sx={{ pt: 3 }}>
                        重設密碼
                    </Typography>
                </Stack>
                <TextField
                    fullWidth
                    size="account"
                    type="account"
                    name="account"
                    label="電郵或聯絡電話"
                    variant="outlined"
                    // onBlur={handleBlur}
                    value={formik.values.account}
                    onChange={formik.handleChange}
                />


                <LoadingButton
                    type="submit"
                    color="primary"
                    loading={loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    重設密碼
                </LoadingButton>

                <Button variant="text" sx={{ fontSize: 'body2', mt:3, p: 0 }}
                    onClick={() => navigate("/login")}>
                    返回登入界面
                </Button>
            </Box>

        </ Card>

    );
}
