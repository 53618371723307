// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { Container } from "@mui/system";
import { useFormik } from "formik";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";
import React from 'react';

import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  FormGroup,
  CardActions,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import ValidationTextField from "../components/ValidationTextField";
import { MemberApi } from "../api/MemberApi";
import { isAllOf } from "@reduxjs/toolkit";
import LoginForm from "../components/LoginForm";
import SkillRankCard from "../components/SkillRankCard";
import SkillRadarChart from "../components/SkillRadarChart";
import { getCurrentAgeGroup } from "../helper/AccountHelper";
import { WidthFull } from "@mui/icons-material";

function RadarChartTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}


function getMemberRadarEvents(eventInfo, ageGroup) {
  var event = eventInfo.event;
  if (event == null) {
    return null;
  }

  var skillSetting = event.skill_setting;
  if (skillSetting == null) {
    return null;
  }

  var skillList = skillSetting[ageGroup];
  if (skillList == null) {
    return null;
  }

  return skillList;
}

function SportRadarChart({ memberEventInfo }) {
  var ageGroup = getCurrentAgeGroup();
  var radarEvents = getMemberRadarEvents(memberEventInfo, ageGroup);

  return (
    <SkillRadarChart skillList={memberEventInfo.skillInfoList} radarItems={radarEvents} />

  )
}

function STEMRadarChart({ memberEventInfo }) {
  var radarSkills = [
    "hoshun_jumping_jack",
    "hoshun_karate",
    "lcdmc_skipping",
    "lht_dance",
    "yw_boxing",
    "pohty_basketball",
  ]

  return (
    <SkillRadarChart skillList={memberEventInfo.skillInfoList} radarItems={radarSkills} />
  )
}

function GameRadarChart({ memberEventInfo }) {
  var radarSkills = [
    "kid_bunny",
    "kid_eagle",
    "kid_kangaroo",
    "kid_duck",
    "kid_monkey",
    
  ]

  return (
    <SkillRadarChart skillList={memberEventInfo.skillInfoList} radarItems={radarSkills} />
  )
}
//


export default function EventDashboardSection({ memberEventInfo }) {

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  }


  var ageGroup = getCurrentAgeGroup();
  var radarEvents = getMemberRadarEvents(memberEventInfo, ageGroup);



  const infoList = memberEventInfo.skillInfoList;
  if (infoList === null || infoList === undefined) {
    console.error("infoList is null");
    return <Box>empty skillInfoList</Box>
  }

  // Remove non-played skill info
  const filteredList = infoList.filter((info) =>
    info.participated !== false);

  const cardList = filteredList.map((info, index) => {
    if (info == null) {
      return <></>
    }
    return (
      <Grid item xs={6} key={index}>
        <SkillRankCard skillInfo={info} index={index} ></SkillRankCard>
      </Grid>
    )
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Sport" />
            <Tab label="STEM-in-PE" />
            <Tab label="Games" />
          </Tabs>
        </Box>
        <RadarChartTabPanel value={selectedTab} index={0} >
          <SportRadarChart memberEventInfo={memberEventInfo} />
        </RadarChartTabPanel>

        <RadarChartTabPanel value={selectedTab} index={1}>
          <STEMRadarChart memberEventInfo={memberEventInfo} />
        </RadarChartTabPanel>
        <RadarChartTabPanel value={selectedTab} index={2}>
          <GameRadarChart memberEventInfo={memberEventInfo} />
        </RadarChartTabPanel>


        {/* The Sport Radar Chart */}
        {/* <SkillRadarChart skillList={memberEventInfo.skillInfoList} radarItems={radarEvents} /> */}
        {/* <pre>
          {JSON.stringify(memberEventInfo.skillInfoList, null, 2)}
        </pre> */}
      </Grid>


      {cardList}

    </Grid>

  );
}
