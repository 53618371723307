// @mui
import { Box, Typography, ButtonBase } from "@mui/material";
import DashboardNumberCard from "./DashboardNumberCard";
import { localizedName } from "../helper/LangHelper";
import { useState, useEffect } from "react";
import SkillScoreDialog from "./SkillScoreDialog";
// ----------------------------------------------------------------------

const skillColorList = [
  "panelRed",
  "panelYellow",
  "panelGreen",
  "panelBlue",
  "panelOrange",
  "panelPurple",
  "panelAmber",
  "panelMagenta",
  "panelSkyBlue",
  "panelCyan",
  "panelRed",
];


const skillColor = {
  situp: "panelRed",
  kungfu: "panelRed",
  vjump: "panelYellow",
  sitnreach: "panelGreen",
  flextest: "panelBlue",
  ttest: "panelOrange",
  handgrip: "panelPurple",
  curl_up: "panelPurple",
  balance: "panelAmber",
  reaction: "panelAmber",
  yingwa_boxing: "panelPurple",
  bike: "panelMagenta",
  yoyo: "panelMagenta",
  punch: "panelSkyBlue",
  skipping: "panelCyan",
  stretching: 'panelSkyBlue',
  moveit_ai_punch: 'panelSkyBlue',
  moveit_quiz: 'panelCyan',
  moveit_coach: 'panelRed',
};

const getEmptySkillInfo = () => {
  return {
    skill: {
      code: "",
      name: "",
      unit: "",
    },
    skill_score: 0,
    rank: 0,
  };
}

const getColor = (index) => {
  const modIndex = index % skillColorList.length;

  return skillColorList[modIndex];
}

export default function SkillRankCard({ skillInfo, index }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const rankStr = "等級";

  const rank = skillInfo.rank;

  if (skillInfo === null || skillInfo === undefined) {
    console.error("skillInfo is null at index=", index);
    return <></>
  }

  if (skillInfo.skill === null || skillInfo.skill === undefined) {
    console.error("skillInfo.skill is null at index=", index);
    return <></>
  }

  let fitnessName = localizedName(skillInfo.skill, "fitness_type");
  if (fitnessName === "" || fitnessName === undefined || fitnessName === null) {
    fitnessName = "未知項目";
  }
  const unitName = skillInfo.skill.unit;
  let testName = localizedName(skillInfo.skill, "name");
  testName = `( ${testName}: ${skillInfo.skill_score} ${unitName} )`;

  let color = getColor(index); // skillColor[skillInfo.skill.code] || "primary";

  useEffect(() => {
    console.log("dialogOpen", dialogOpen);
  }, [dialogOpen]);

  // const sk
  const handleCloseDialog = () => {
    console.debug("handleCloseDialog");
    setDialogOpen(false);
    // console.debug("dialogOpen=", dialogOpen);
  };

  if (skillInfo === null || skillInfo === undefined) {

    return (
      <Box>
        <Typography>無資料</Typography>
      </Box>
    )
  }

  return (
    <>
      <SkillScoreDialog key={index}
        skillInfo={skillInfo}
        open={dialogOpen}
        onClose={() => {
          handleCloseDialog();
        }}
      ></SkillScoreDialog>
      <Box
        component={ButtonBase}
        onClick={() => {
          setDialogOpen(true);
        }}
        sx={{ width: "100%" }}
      >
        {/* <pre>
                        XXX : {index}
                    </pre> */}


        <DashboardNumberCard
          heading={rankStr}
          sx={{ width: "100%" }}
          number={rank}
          caption={fitnessName}
          subCaption={testName}
          color={color}
        />
      </Box>
    </>
  );
}
