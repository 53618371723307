// import { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
// } from "@mui/material";

import { Container } from "@mui/system";

import { Box, Grid } from "@mui/material";

import RegisterSection from "../sections/RegisterSection";

export default function Register() {
  return (
    <Container maxWidth={"md"}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <RegisterSection />
      </Grid>
    </Container>
  );
}
