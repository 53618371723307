// import logo from './logo.svg';
import { Suspense } from "react";
import "./App.css";
// import "./styles/globals.css";
// import Button from '@mui/material/Button';
// import AcUnitIcon from '@mui/icons-material/AcUnit';
import Router from "./routes";
import MyThemeProvider from "./components/MyThemeProvider";

import { CssBaseline } from "@mui/material"; // ken: The CssBaseline component helps to kickstart an elegant, consistent, and simple baseline to build upon.

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <MyThemeProvider>
        <CssBaseline />
        <Router></Router>
      </MyThemeProvider>
    </Suspense>
  );
}

export default App;
