// const defaultLang = "en";   // available languages: en, zh

const langList = ["zh-HK", "en"];
const defaultLang = langList[0];
export const getLang = (format = false) => {
  var lang = localStorage.getItem("lang");
  initLang(lang);
  if (format) {
    return lang.split("-")[0];
  }
  return lang;
};

const initLang = (lang) => {
  if (lang === false || lang === null || lang === undefined) {
    localStorage.setItem("lang", defaultLang);
    return defaultLang;
  }
};

export const changeLang = (lang = "") => {
  if (langList.includes(lang)) {
    localStorage.setItem("lang", lang);
    return lang;
  }

  var currentLang = localStorage.getItem("lang");
  //   console.log("getLang: ", lang);
  initLang(currentLang);
  let newLang = currentLang === langList[0] ? langList[1] : langList[0];

  localStorage.setItem("lang", newLang);
  return newLang;
  //   console.log("changeLang: ", getLang());
};

export const localizedName = (dataObject, fieldName) => {
  var lang = getLang();

  const localizedName = lang === "en" ? `${fieldName}_en` : `${fieldName}_zh`;

  return dataObject[localizedName];
};
