import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";

export default function TanitaMuscleBalanceCard({ data }) {
    const image = {
        // 581 × 516
        w: 581, h: 516,
        src: "/assets/tanita/Muscle_Balance.png"
    }
    let armBalance = data.balancearm || 0;
    let legBalance = data.balanceleg || 0;

    let valueToPixelRatio = 10;


    // let 
    let topX = armBalance * valueToPixelRatio; // lerp(115, 596, ageRatio); // age 
    let bottomX = legBalance * valueToPixelRatio;
    
    const markers = [
        {
            type: "dot", x: topX, y: 145, r: 10, color: "transparent",
            stroke: "blue", strokeWidth: 4,
            origin: "center"
        },
        {
            type: "dot", x: bottomX, y: -193, r: 10, color: "transparent",
            stroke: "blue", strokeWidth: 4,
            origin: "center"
        }
    ];

    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />
        </Stack>
    </Card>
}