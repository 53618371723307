import * as Yup from "yup";

const Schema = Yup.object().shape({
  age: Yup.number().required("請輸入年齡"),
  schoolName: Yup.string().required("請輸入學校名稱"),
  q1: Yup.number().min(0, "請選擇一個選項"),
  q2: Yup.number().min(0, "請選擇一個選項"),
  q3: Yup.number().min(0, "請選擇一個選項"),
  q4: Yup.number().min(0, "請選擇一個選項"),
  q5: Yup.number().min(0, "請選擇一個選項"),
  q6: Yup.number().min(0, "請選擇一個選項"),
  q7: Yup.number().min(0, "請選擇一個選項"),
  name: Yup.string().required("請輸入姓名"),
  otherSchoolName: Yup.string().when("schoolName", {
    is: (schoolName) => schoolName === "其他",
    then: Yup.string().required("請輸入學校名稱"),
  }),
  hkid: Yup.string()
    .min(5, "請輸入5位身分證號碼")
    .max(5, "請輸入5位身分證號碼")
    .matches(/^[A-Za-z]{1}[0-9]{4}$/, "請輸入有效身分證號碼")
    .required("請輸入身份證號碼"),
  witness: Yup.string().when("age", {
    is: (age) => age < 18,
    then: Yup.string().required("請輸入見證人姓名"),
  }),
  selfConfirm: Yup.bool().oneOf([true], "本人必須確認"),
  witnessConfirm: Yup.bool().when("witness", {
    is: (witness) => !!witness,
    then: Yup.bool().oneOf([true]),
  }),
});

const FormikInstance = {
  initialValues: {
    age: 20,
    identity: "student_teacher",
    schoolLevel: "primary_school",
    schoolName: "",
    otherSchoolName: "",
    area: "",
    eduGrade: 1,
    isPregnant: false,
    q1: -1,
    q2: -1,
    q3: -1,
    q4: -1,
    q5: -1,
    q6: -1,
    q7: -1,
    name: "",
    hkid: "",
    witness: "",
    selfConfirm: false,
    witnessConfirm: false,
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const Content = {
  Schema,
  //FormControl,
  FormikInstance,
};

export default Content;
