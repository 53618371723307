import React, { } from "react";
import { Card, Stack } from "@mui/material";
import SvgDiagram from "../SvgDiagram";
import { clamp, lerp, ratio } from "../../helper/MathHelper";

export default function TanitaBasicHealthChartCard({ data }) {
    const image = {
        w: 952, h: 463,
        src: "/assets/tanita/Body_Composition_Analysis_Result_2.png"
    }

    // age = age || 0;

    // // age = Math.c
    // fat = fat || 0;

    // let ageRatio = ratio(age, 20, 90);
    // let fatRatio = ratio(fat, 0, 3.0); // clamp(fat, 0, 3.0) / 50.0;

    // // let 
    // let x = lerp(115, 596, ageRatio); // age 
    // let y = lerp(70, 415, fatRatio); // fat
    const fatWeight = 80.0;
    const bone = 28.4;
    const dummy = 999.9;

    // const tbw = parseFloat(data.tbw);
    const boneMass = parseFloat(data.bone_mass);
    const ecw = parseFloat(data.ecw);
    const icw = parseFloat(data.icw);

    const weight = parseFloat(data.weight);
    const ffm = parseFloat(data.ffm);
    const muscleMass = parseFloat(data.muscle_mass);
    const fatMass = parseFloat(data.fat_mass);

    const muscleMassDiff = muscleMass - ffm;
    const tbw = parseFloat(data.tbw); // parseFloat(data.muscle_mass);
    const cellValue = 11;

    const upperValues = [fatMass, boneMass, muscleMassDiff, ecw];
    const lowerValues = [weight, ffm, muscleMass, tbw, icw];

    const offset = 186;
    const upperMarkers = upperValues.map((value, index) => {
        return {
            type: "text", x: 260 + offset * index, y: 50,
            fontSize: 2, text: value.toFixed(1), color: "black"
        }
    });

    const lowerMarkers = lowerValues.map((value, index) => {
        return {
            type: "text", x: 80 + offset * index, y: 70,
            fontSize: 2, text: value.toFixed(1), color: "black",
            origin: "bottom-left"
        }
    });




    const markers = [
        ...upperMarkers,
        ...lowerMarkers,
        // { type: "text", x: 260, y: 50, fontSize:2, text: fatWeightStr, color: "black", origin: "" },
        // { type: "text", x: 260 + offset, y: 50, fontSize:2, text: boneStr, color: "black", origin: "" },

        //    { type: "dot", x: x, y: y, r: 10, color: "red", origin: "bottom-left" }
    ];
    // weight	fat_p	fat_mass	ffm	muscle_mass
    // bmi 
    // metabolic_age
    // target_fat_p	predicted_weight	predicted_fat_mass


    // const imageW = 711;
    // const imageH = 531;
    return <Card sx={{ maxWidth: 900 }}>
        <Stack direction="column">
            <SvgDiagram image={image} markers={markers} />
            {/* <pre>
                {JSON.stringify(data, null, 2)}
            </pre> */}
        </Stack>
    </Card>
}