import { useDispatch } from "react-redux";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import { MemberApi } from "../api/MemberApi";
import { updateAuthState } from "../reducers/userSlice";
// import { gen}
import TemiHelper from "../helper/TemiHelper";
import { Card, CardContent, Button, Stack, Grid } from "@mui/material";

export default function Component({
  sx, mode,
  memberEventInfo,
  redemptionInfo
}) {
  const dispatch = useDispatch();

  const handleReload = () => {
    window.location.reload();
  };

  const eventMode = mode === undefined ? "checkin" : mode;

  const isCheckIn = eventMode === "checkin" ? true : false;

  const getExitData = (_mode) => {
    let exitData = {
      useremail: memberEventInfo?.member?.email ?? "",
    }


    if(_mode === "checkout"){
      exitData["gift_code"] = redemptionInfo?.qrcode ?? ""
      exitData["redeem_status"] = redemptionInfo?.status ?? ""
    }

    return exitData;
  }

  const handleLogout = async () => {
    const exitData = getExitData(eventMode)


    const exitUrl = isCheckIn ?
      TemiHelper.generateCheckInLink(exitData)
      : TemiHelper.generateCheckOutLink(exitData);

    dispatch(updateAuthState(false));
    removeLocalStorageUser();
    await MemberApi.logout();
    window.location.href = exitUrl;
  };



  return (
    <Grid
      container
      columnSpacing={3}
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid item xs={6} sm={6}>
        <Button
          variant="contained"
          color="info"
          onClick={handleReload}
          fullWidth
        >
          Refresh Data
        </Button>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Button
          variant="contained"
          color="error"
          onClick={handleLogout}
          fullWidth
        >
          Back to Temi
        </Button>
      </Grid>
      <Grid item xs={12} sm={12}>
        <pre>
          {/* {JSON.stringify(memberEventInfo, null, 2)}

          {JSON.stringify(redemptionInfo, null, 2)} */}
        </pre>

      </Grid>
    </Grid>
  );
}
