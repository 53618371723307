import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Stack,
  Typography
  //   Box,
  //   Typography,
  //   TextField,

  //   Grid,
  //   Card,
  //   CardActions,
  //   CardContent,
  //   FormGroup,
} from "@mui/material";
import { MemberApi } from "../api/MemberApi";
import { useSelector, useDispatch } from "react-redux";
import { memberID, updateAuthState } from "../reducers/userSlice";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import TanitaSection from "../sections/TanitaSection";
import { getStoredAccount } from "../helper/AccountHelper";

export default function Home() {
  const dispatch = useDispatch();

  const account = getStoredAccount();
  const currentMemberID = account === undefined || account === null ?
                                0 : account.id;

  return (


    <Stack direction="column" >
      <Typography sx={{ mb: 4 }} variant="h3">健康分析報告</Typography>

      <TanitaSection memberID={currentMemberID} autoLoad={true} />
      {/* <Box>
          <Typography variant="h5">VTC Skill Passport</Typography>
        </Box>
        <Typography>
        currentMemberID: {currentMemberID}
        </Typography>
        <Box>
          <Button variant="outlined" onClick={() => {
            onLogout();
          }}>
            Logout
          </Button>
        </Box>
      </Grid> */}
      <Typography variant="body1">Powered by <b>Tanita</b></Typography>
    </Stack>
  );
}
